import gql from 'graphql-tag'

export const GET_PROFILE_QUERY = gql`
  query GetPartnerProfile {
    partner {
      getProfile {
        partner {
          name
          partnerId
          agentCode
          email
          walletType
          walletNumber
          currentBalance
          lifetimeRevenue
          lifetimePaid
          referralPercent
          postbackUrl
        }
        agent {
          profitType
          workMode
          profitFee
          crossalesEnable
          crossalesFee
        }
        referrals {
          name
          status
        }
      }
    }
  }
`

export const EDIT_MUTATION = gql`
  mutation Edit (
  $email: EmailAddress!,
  $passwordOld: String!,
  $passwordNew: String,
  ) {
    partner {
      editSelf(input: {
        email: $email,
        passwordOld: $passwordOld,
        passwordNew: $passwordNew,
      }) {
        email
      }
    }
  }
`
