import styled from 'styled-components'
import TextLarge from '@kupibilet/ui/components/Typography/TextLarge'
import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'
import mq from '@kupibilet/ui/utils/media-queries'
import skyLeft from './assets/sky-left.svg'
import skyRight from './assets/sky-right.svg'
import searchForm from './assets/search-form.svg'

const StyledSingleFluidSection = styled(SingleFluidSection)`
   background: url(${skyLeft}) top left no-repeat, url(${skyRight}) top right no-repeat;
   background-color: ${({ theme }) => theme.color.miscLightest};
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 42px 18px 90px;
   ${mq.handheld`
     max-width: 1176px;
     margin: 0;
     padding: 42px 18px 90px;
  `}
   ${mq.mobile`
     padding: 60px 18px 72px;
  `}
`

const Subtitle = styled(TextLarge)`
  max-width: 630px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 35px;
  ${mq.mobile`
    margin-bottom: 24px;
  `}
`

const StyledSearchForm = styled.div`
  background-image: url(${searchForm});
  background-size: contain;
  background-repeat: no-repeat;
  width: 480px;
  height: 76px;
  ${mq.mobile`
    width: 286px;
    height: 45px;
  `}
`

export {
  StyledSingleFluidSection,
  Subtitle,
  StyledSearchForm,
}
