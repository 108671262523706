import React from 'react'
import { Loading } from 'shared/components'

function withQueryLoading(fn) {
  return function WrappedRenderFunction(props) {
    if (props.loading) {
      return <Loading />
    }

    return fn(props)
  }
}

export default withQueryLoading
