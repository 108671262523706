import React, { PureComponent } from 'react'
import get from 'lodash/get'
import type { FieldAttributes } from 'formik'
import { Select } from '@kupibilet/ui/components/Select'
import FormItem from '@kupibilet/ui/components/FormItem'
import { InputContainer } from 'shared/components/FormInput'

class FormSelect extends PureComponent<FieldAttributes> {
  changeFieldValue = (event, { suggestion }) => {
    const { form, field, onChange } = this.props
    form.setFieldValue(field.name, suggestion.value)

    if (onChange) {
      onChange(suggestion.value)
    }
  }

  onBlur = () => {
    const { form, field } = this.props
    form.setFieldTouched(field.name, true)
  }

  render() {
    const {
      label,
      form: {
        touched,
        errors,
      },
      field,
      options, // [ { value, text? } ]
    } = this.props

    const fieldValue = field.value
    const selectedOption = options.find((option) => option.value === fieldValue)
    const fieldText = get(selectedOption, 'text', fieldValue)

    return (
      <InputContainer>
        <FormItem label={label}>
          <Select
            suggestions={options}
            inputProps={{
              ...field,
              ...this.props,
              onBlur: this.onBlur,
            }}
            selectedSuggestion={{ value: fieldText }}
            onSuggestionSelected={this.changeFieldValue}
            getSuggestionKey={({ text, value }) => text || value}
            getSuggestionValue={({ text, value }) => text || value}
            error={touched[field.name] && errors[field.name]}
          />
        </FormItem>
      </InputContainer>
    )
  }
}

export default FormSelect
