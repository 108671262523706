import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import UIH1 from '@kupibilet/ui/components/Typography/H1'
import UIH3 from '@kupibilet/ui/components/Typography/H3'
import UIH6 from '@kupibilet/ui/components/Typography/H6'
import style from 'shared/utils/styleAs'

export const H1 = styled(UIH1)`
  margin-top: 54px;
  margin-bottom: 36px;
  ${mq.tablet`
    margin-top: 30px;
    margin-bottom: 24px;
  `}
  ${mq.mobile`
    margin-top: 24px;
    margin-bottom: 18px;
  `}
`

export const H2 = style(UIH3).as('h2')`
  margin-top: 36px;
  margin-bottom: 24px;
  font-weight: 700;
  ${mq.tablet`
    margin-top: 24px;
    margin-bottom: 18px;
  `}
  ${mq.mobile`
    margin-top: 18px;
    margin-bottom: 12px;
  `}
`

export const H3 = style(UIH6).as('h3')`
  margin-bottom: 18px;
  font-weight: 700;
  ${mq.mobile`
    margin-bottom: 12px;
  `}
`
