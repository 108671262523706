import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import UILink from '@kupibilet/ui/components/Link'

export const StyledLink = styled(UILink)`
  margin-right: 18px;
  ${mq.mobile`
    margin-right: 0;
  `}
  & + & {
    ${mq.mobile`
      margin-left: 12px;
    `}
  }
`
