// @flow
import React from 'react'
import _ from 'lodash'
import { Query } from 'react-apollo'
import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import {
  Container,
  PageTitle,
  UnexpectedError,
  H1,
  Balance,
  FlexBoxContainer,
} from 'shared/components'
import withQueryLoading from 'shared/HOC/withQueryLoading'
import type { ApolloQuery } from 'shared/types'
import withRedirectIfNotAuthorized from 'partner/HOC/withRedirectIfNotAuthorized'
import { GET_PROFILE_QUERY } from './queries'
import EditForm from './parts/EditForm'
import Referrals from './parts/Referrals'
import PartnerInfo from './parts/PartnerInfo'

const StyledFlexBoxContainer = styled(FlexBoxContainer)`
  ${mq.handheld`
    flex-direction: column-reverse;
  `}
`
const BalanceWrapper = styled.div`
  ${mq.handheld`
    margin-bottom: 18px;
  `}
`

const profileRenderFunction = ({ error, data }: ApolloQuery) => {
  if (error) return <UnexpectedError />
  const partner = _.get(data, 'partner.getProfile.partner', null)
  const agent = _.get(data, 'partner.getProfile.agent', null)
  const referrals = _.get(data, 'partner.getProfile.referrals', [])
  const referralPercent = _.get(partner, 'referralPercent', '')
  const email = _.get(partner, 'email', '')
  const postbackUrl = _.get(partner, 'postbackUrl', '')
  const workMode = _.get(agent, 'workMode', '')
  const agentCode = _.get(partner, 'agentCode', '')

  return (
    <Container>
      <PageTitle>Профиль</PageTitle>
      <H1>Профиль</H1>
      <StyledFlexBoxContainer>
        <PartnerInfo {...partner} {...agent} />
        <BalanceWrapper>
          <Balance {...partner} />
        </BalanceWrapper>
      </StyledFlexBoxContainer>
      <EditForm
        email={email}
        postbackUrl={postbackUrl}
        workMode={workMode}
      />
      <Referrals
        referrals={referrals}
        referralPercent={referralPercent}
        agentCode={agentCode}
      />
    </Container>
  )
}

const Profile = () => (
  <Query query={GET_PROFILE_QUERY}>
    {withQueryLoading(profileRenderFunction)}
  </Query>
)

export default withRedirectIfNotAuthorized(Profile)
