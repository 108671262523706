// @flow
import * as React from 'react'
import { Mutation } from 'react-apollo'
import get from 'lodash/get'
import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'
import { PageTitle, H1 } from 'shared/components'

import { trackEvent } from 'partner/utils/tracking'
import withRedirectIfAuthorized from 'partner/HOC/withRedirectIfAuthorized'

import Form from './parts/Form'
import SuccessfulRegistration from './parts/SuccessfulRegistration'
import { REGISTER_MUTATION } from './queries'
import { PleasedRabbitWrapper, PleasedRabbit } from './styled'

const Registration = () => (
  <Mutation mutation={REGISTER_MUTATION}>
    {(register, { loading, error, data }) => {
      const partnerEmail = get(data, 'partner.create.email', null)
      if (partnerEmail) {
        trackEvent('registration_page.submit.valid')
        return <SuccessfulRegistration partnerEmail={partnerEmail} />
      }

      const graphQLError = get(error, 'graphQLErrors[0].message', null)

      return (
        <SingleFluidSection>
          <PageTitle>Регистрация</PageTitle>
          <H1>Регистрация</H1>
          <Form register={register} graphQLError={graphQLError} loading={loading} />
          <PleasedRabbitWrapper>
            <PleasedRabbit />
          </PleasedRabbitWrapper>
        </SingleFluidSection>
      )
    }}
  </Mutation>
)

export default withRedirectIfAuthorized(Registration)
