// @flow
import React from 'react'
import { Mutation } from 'react-apollo'
import _ from 'lodash'
import { CREATED_PAYMENT_NOT_PAID } from 'consts/apiErrors'
import { ErrorText, SuccessText } from 'shared/components'
import { CREATE_PAYMENT } from './queries'

type Props = {
  partnerId: string,
  renderComponent: () => React.Node,
}

const CreatePayment = ({ partnerId, renderComponent }: Props) => (
  <Mutation mutation={CREATE_PAYMENT} variables={{ partnerId }}>
    {(create, { data, error }) => {
      if (data) return <SuccessText>Выплата создана</SuccessText>
      if (error) {
        const graphQLError = _.get(error, 'graphQLErrors[0].message', null)
        if (graphQLError === CREATED_PAYMENT_NOT_PAID) {
          return <ErrorText>Выплата уже создана</ErrorText>
        }
        return <ErrorText>Ошибка</ErrorText>
      }
      return renderComponent(create)
    }}
  </Mutation>
)

export default CreatePayment
