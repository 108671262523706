import React from 'react'
import { H2 } from 'shared/components'
import RegisterButton from '../blocks/RegisterButton'
import { StyledSingleFluidSection } from './styled'

const Register = () => (
  <StyledSingleFluidSection>
    <H2>
      Зарабатывайте вместе с нами!
    </H2>
    <RegisterButton />
  </StyledSingleFluidSection>
)

export default Register
