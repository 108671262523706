import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import WithLabel from 'shared/pages/Constructors/components/WithLabel'
import { Creators } from './ducks'
import { Container, StyledSelect, StyledButton } from './styled'

const getSuggestionKeyOrValue = (marker) => marker
const getSuggestionValue = (marker) => marker || '(Без маркера)'

class Marker extends React.PureComponent {
  createMarker = (e) => {
    const { createMarker } = this.props
    e.preventDefault()

    const marker = (prompt('Новый маркер: (только латиница и цифры длинной до 10 символов)') || '').trim()
    const isValid = !/[^\w-]/.test(marker) && marker.length <= 10
    if (marker && isValid) {
      createMarker(marker)
    } else if (marker && !isValid) {
      alert('Только латиница и цифры длинной до 10 символов')
    }
  }

  markerSelected = (e, { suggestion }) => {
    const { markerSelected } = this.props
    markerSelected(suggestion)
  }

  deleteMarker = (e) => {
    const { deleteMarker, selectedMarker } = this.props
    e.preventDefault()

    deleteMarker(selectedMarker)
  }

  render() {
    const { markers, selectedMarker } = this.props

    return (
      <WithLabel label="Маркер" htmlFor="marker">
        <Container>
          <StyledSelect
            suggestions={markers}
            onSuggestionSelected={this.markerSelected}
            getSuggestionKey={getSuggestionKeyOrValue}
            getSuggestionValue={getSuggestionValue}
            selectedSuggestion={selectedMarker}
            inputProps={{
              id: 'marker',
              placeholder: 'Маркер',
              meta: {
                active: true,
              },
            }}
            size="small"
          />
          <StyledButton onClick={this.createMarker} size="small" icon="plus" />
          { selectedMarker &&
            <StyledButton onClick={this.deleteMarker} size="small" icon="minus" />
          }
        </Container>
      </WithLabel>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(Creators, dispatch),
})

const mapStateToProps = (state) => ({
  markers: state.markers.markers,
  selectedMarker: state.markers.selectedMarker,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(Marker)
