import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import _ from 'lodash'

import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'
import { PageTitle, LoginForm, H1 } from 'shared/components'
import AppContext from 'partner/contexts/AppContext'

import withRedirectIfAuthorized from 'partner/HOC/withRedirectIfAuthorized'

import { LOGIN_MUTATION } from './queries'

class Login extends Component {
  static contextType = AppContext

  handleSuccessfulLogin = (data) => {
    const { setAppState } = this.context
    const email = _.get(data, 'partner.login.partner.email', null)
    setAppState({ email })
  }

  render() {
    return (
      <Mutation mutation={LOGIN_MUTATION} onCompleted={this.handleSuccessfulLogin}>
        {(login, { loading, error }) => {
          const graphQLError = _.get(error, 'graphQLErrors[0].message', null)

          return (
            <SingleFluidSection>
              <PageTitle>Авторизация</PageTitle>
              <H1>Авторизация</H1>
              <LoginForm login={login} graphQLError={graphQLError} loading={loading} />
            </SingleFluidSection>
          )
        }}
      </Mutation>
    )
  }
}

export default withRedirectIfAuthorized(Login)
