import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'
import { FlexBoxContainer } from 'shared/components'

const StyledSingleFLuidSection = styled(SingleFluidSection)`
  padding: 60px 42px 90px;
  ${mq.tablet`
    padding: 24px 20px 54px;
  `}
  ${mq.mobile`
    padding: 6px 0 18px;
  `}
`

const StyledContainer = styled(FlexBoxContainer)`
  margin-top: 36px;
  flex-wrap: wrap;
  ${mq.tablet`
    margin-top: 24px;
    flex-direction: row;
  `}
  ${mq.mobile`
    margin-top: 18px;
    flex-direction: column;
  `}
`

export {
  StyledContainer,
  StyledSingleFLuidSection,
}
