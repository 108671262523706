import React from 'react'
import styled from 'styled-components'

const style = (Component) => ({
  as: (asProp) => (css, ...values) => {
    const StyledComp = styled(Component)(css, ...values)
    return (props) => <StyledComp as={asProp} {...props} />
  },
})

export default style
