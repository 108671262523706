import React from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle } from 'styled-components'
import ThemeProvider from '@kupibilet/ui/components/ThemeProvider'
import initIcons from '@kupibilet/icons'


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`

const injectIcons = () => {
  const iconsDiv = document.createElement('div')
  iconsDiv.style = 'display:none;'
  iconsDiv.innerHTML = initIcons()
  document.querySelector('body').appendChild(iconsDiv)
}

const withResponsiveTheme = (Component) => (
  class extends React.Component {
    componentDidMount() {
      document.querySelector('body').classList.add('responsive')
      injectIcons()
    }

    render() {
      return (
        <ThemeProvider>
          <GlobalStyle />
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* TODO: store our font locally */}
            <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&amp;subset=cyrillic,cyrillic-ext" rel="stylesheet" />
          </Helmet>
          <Component {...this.props} />
        </ThemeProvider>
      )
    }
  }
)


export default withResponsiveTheme
