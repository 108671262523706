import {
  ACCESS_DENIED,
  EMAIL_ALREADY_TAKEN,
  PARTNER_NOT_FOUND,
  TOKEN_EXPIRED,
  WRONG_OLD_PASSWORD,
  PARTNER_BLOCKED,
  FAILED_TO_AUTHORIZE,
  NOTHING_TO_UPDATE,
  ALREADY_AUTHORIZED,
  WRONG_CREDENTIALS,
  INVALID_PASSWORD_OR_EMAIL,
  UNEXPECTED_ERROR,
  NO_PERMISSIONS,
  FAILED_PAYMENT_REMOVAL,
  NO_PAYMENTS_TO_MARK,
  FAILED_PAYMENT_MARKING,
  FAILED_PAYMENT_CREATION,
  CREATED_PAYMENT_NOT_PAID,
  BALANCE_NOT_UPDATED,
  PARTNER_HAS_ZERO_BALANCE,
} from 'consts/apiErrors'

const translations = {
  [ACCESS_DENIED]: 'Доступ запрещён',
  [EMAIL_ALREADY_TAKEN]: 'Адрес электронной почты уже используется',
  [PARTNER_NOT_FOUND]: 'Партнёр не найден',
  [TOKEN_EXPIRED]: 'Истекло время сессии. Авторизуйтесь снова',
  [WRONG_OLD_PASSWORD]: 'Текущий пароль введён неверно',
  [PARTNER_BLOCKED]: 'Партнёр заблокирован',
  [FAILED_TO_AUTHORIZE]: 'Не удалось авторизоваться',
  [NOTHING_TO_UPDATE]: 'Обновлять ничего не требуется',
  [ALREADY_AUTHORIZED]: 'Вы уже авторизованы',
  [WRONG_CREDENTIALS]: 'Вы неправильно ввели электронную почту или пароль',
  [INVALID_PASSWORD_OR_EMAIL]: 'Некорректный пароль или адрес электронной почты',
  [UNEXPECTED_ERROR]: 'Неизвестная ошибка',
  [NO_PERMISSIONS]: 'Нет прав на выполнение этой операции',
  [FAILED_PAYMENT_REMOVAL]: 'Не удалось исключить из выплаты',
  [NO_PAYMENTS_TO_MARK]: 'Нет выплат к оплате',
  [FAILED_PAYMENT_MARKING]: 'Не удалось сменить статус выплатам',
  [FAILED_PAYMENT_CREATION]: 'Не удалось создать выплату',
  [CREATED_PAYMENT_NOT_PAID]: 'Уже имеется неоплаченная выплата',
  [BALANCE_NOT_UPDATED]: 'Не удалось обновить баланс',
  [PARTNER_HAS_ZERO_BALANCE]: 'У партнёра нет средств на балансе',
}

const translateApiError = (error) => translations[error] || translations[UNEXPECTED_ERROR]

export default translateApiError
