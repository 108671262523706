import React from 'react'
import { H2 } from 'shared/components'
import Card from './components/Card'
import { StyledContainer, StyledSingleFLuidSection } from './styled'
import { AUDIENCE } from './parts/texts'

const TargetAudience = () => (
  <StyledSingleFLuidSection>
    <H2>
      Кто может заработать на партнёрской программе?
    </H2>
    <StyledContainer>
      {Object.entries(AUDIENCE).map(([key, { text }]) => (
        <Card key={key} text={text} />
      ))}
    </StyledContainer>
  </StyledSingleFLuidSection>
)

export default TargetAudience
