import injectScript from './injectScript'

const YA_COUNTER_ID = 54451633
const noop = () => {}
const isProd = process.env.NODE_ENV === 'production'
let scriptInjectingPromise

function initScript() {
  if (scriptInjectingPromise) {
    return scriptInjectingPromise
  }

  if (!isProd) {
    window.ym = noop // suppress errors when running on dev
    scriptInjectingPromise = Promise.resolve()
    return scriptInjectingPromise
  }

  scriptInjectingPromise = new Promise((resolve, reject) => {
    window.ym = (window.ym || ((...args) => {
      window.ym.a = window.ym.a || []
      window.ym.a.push(args)
    }))
    window.ym.l = Number(new Date())
    try {
      window.ym(YA_COUNTER_ID, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      })
      resolve()
    } catch (err) {
      reject(err)
    }

    injectScript('//mc.yandex.ru/metrika/tag.js').catch((err) => {
      console.warn('[YM] Failed to inject script', err)
      reject(err)
    })
  })

  return scriptInjectingPromise
}

export async function ymReachGoal(goal) {
  try {
    console.log('[YM] Reach goal', goal)
    await initScript()
    window.ym(YA_COUNTER_ID, 'reachGoal', goal)
  } catch (err) {
    console.warn('[YM] Failed to reach goal', err)
  }
}
