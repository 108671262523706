import styled from 'styled-components'
import SeparatorImage from '@kupibilet/ui/components/SeparatorImage'
import mq from '@kupibilet/ui/utils/media-queries'

const StyledSeparatorImage = styled(SeparatorImage)`
  height: 286px;
  background-size: cover;
  background-position: center top;
  ${mq.mobile`
    height: 102px;
  `}
`

export {
  StyledSeparatorImage,
}
