import styled from 'styled-components'
import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'
import mq from '@kupibilet/ui/utils/media-queries'

const StyledSingleFluidSection = styled(SingleFluidSection)`
  text-align: center;
  padding-bottom: 90px;
  ${mq.tablet`
    padding-bottom: 54px;
  `}
  ${mq.mobile`
    padding-bottom: 18px;
  `}
`

export {
  StyledSingleFluidSection,
}
