// @flow
import React from 'react'
import { Formik, Field, Form } from 'formik'
import Button from '@kupibilet/ui/components/Button'
import { FormInput, FormRow, AlertApiError } from 'shared/components'

import { EMAIL, STRING } from 'shared/validation/validators'
import { createValidationSchema } from 'shared/validation/'

type Props = {
  graphQLError: string | null,
  loading: Boolean,
  login: () => void,
}

const FORM_INITIAL_VALUES = {
  email: '',
  password: '',
}

const validationSchema = createValidationSchema({
  email: EMAIL,
  password: STRING,
})

const handleFormSubmit = (login, variables) => {
  login({
    variables: {
      ...variables,
      email: variables.email.toLowerCase(),
    },
  })
}

const LoginForm = ({ graphQLError, login, loading }: Props) => (
  <Formik
    initialValues={FORM_INITIAL_VALUES}
    validationSchema={validationSchema}
    onSubmit={(...args) => handleFormSubmit(login, ...args)}
  >
    <Form>
      <FormRow>
        <Field
          component={FormInput}
          name="email"
          label="Email"
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormInput}
          name="password"
          label="Пароль"
          type="password"
        />
      </FormRow>

      {graphQLError && (
        <FormRow>
          <AlertApiError error={graphQLError} />
        </FormRow>
      )}

      <Button size="large" type="submit" disabled={loading}>Войти</Button>
    </Form>
  </Formik>
)

export default LoginForm
