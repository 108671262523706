import TextSmall from '@kupibilet/ui/components/Typography/TextSmall'
import style from 'shared/utils/styleAs'

const Tip = style(TextSmall).as('blockquote')`
  border-left: 3px solid ${({ theme }) => theme.color.miscLighter};
  padding: 8px 0 8px 12px;
  margin: 15px 0 15px 3px;
`

export default Tip
