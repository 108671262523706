import React, { Component } from 'react'
import { PageTitle, Container, H1, StatsTable } from 'shared/components'
import withRedirectIfNotAuthorized from 'partner/HOC/withRedirectIfNotAuthorized'

class Stats extends Component {
  render() {
    return (
      <Container>
        <PageTitle>Статистика</PageTitle>
        <H1>Статистика</H1>
        <StatsTable
          statsType="partnerStats"
          hasGroupingByTag
        />
      </Container>
    )
  }
}

export default withRedirectIfNotAuthorized(Stats)
