// @flow
import * as React from 'react'
import styled from 'styled-components'

import Text from '@kupibilet/ui/components/Typography/Text'
import TextSmall from '@kupibilet/ui/components/Typography/TextSmall'

type Props = {
  isCPC: boolean,
}

const TextTitle = styled(Text)`
  margin-bottom: 12px;
`

const StyledText = styled(TextSmall)`
  padding-left: 20px;
  margin: 0;
`

const Wrapper = styled.div`
  padding-bottom: 24px;
`

const PostbackUrlDescription = ({ isCPC }: Props) => (
  <Wrapper>
    <TextTitle as="h3">Параметры Postback Url для {isCPC ? 'CPC' : 'CPA'}</TextTitle>
    <StyledText as="ul">
      {isCPC ? (
        <>
          <li><strong>marker</strong> — маркер</li>
        </>
      ) : (
        <>
          <li><strong>departure</strong> (IATA) — аэропорт вылета</li>
          <li><strong>arrival</strong> (IATA) — аэропорт прилёта</li>
          <li><strong>departure_at</strong> (YYYY-MM-DD) — дата вылета</li>
          <li><strong>arrival_at</strong> (YYYY-MM-DD) — дата прилёта</li>
          <li><strong>marker</strong> — маркер</li>
          <li><strong>sum</strong> — сумма заказа</li>
          <li><strong>order_number</strong> — номер заказа</li>
          <li><strong>cabin_class</strong> — класс</li>
          <li><strong>adults</strong> — количество взрослых в заказе</li>
          <li><strong>children</strong> — количество детей в заказе</li>
        </>
      )}

    </StyledText>
  </Wrapper>
)

export default PostbackUrlDescription
