import _ from 'lodash'

/*
 * In our UI we have checkbox groups like:
 *
 * [_] All
 * [_] Option 1
 * [_] Option 2
 * [_] Option 3
 *
 * In checkbox group there is a set of rules:
 * 1. When `All` changes to `checked` all options should be marked as `checked`
 * 2. When `All` changes to `not checked` all options should be marked as `not checked`
 * 3. When setting `not checked` to any option `All` should be marked as `not checked`
 * 4. When setting `checked` to last not checked option `All` should be marked as `checked`
 *
 * `updateCheckboxGroup` is a function that helps to manage changes in checkbox group
 * It accepts new state of option and its name and all the checkbox group
 *
 * */
function updateCheckboxGroup({ checked, name, group }) {
  // If clicked `All` change all options to `All` state
  if (name === 'all') {
    return _.mapValues(group, () => checked)
  }

  const nextGroup = {
    ...group,
    [name]: checked,
  }

  // If at least one option is not checked, mark `All` as `not checked`
  if (!checked) {
    nextGroup.all = false
  }

  // If all options are marked as `checked` mark `All` as `checked`
  if (Object.values(nextGroup).filter((x) => !x).length === 1) {
    nextGroup.all = true
  }

  return nextGroup
}

export default updateCheckboxGroup
