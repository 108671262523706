const ACCESS_DENIED = 'ACCESS_DENIED'
const EMAIL_ALREADY_TAKEN = 'EMAIL_ALREADY_TAKEN'
const PARTNER_NOT_FOUND = 'PARTNER_NOT_FOUND'
const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
const WRONG_OLD_PASSWORD = 'WRONG_OLD_PASSWORD'
const PARTNER_BLOCKED = 'PARTNER_BLOCKED'
const FAILED_TO_AUTHORIZE = 'FAILED_TO_AUTHORIZE'
const NOTHING_TO_UPDATE = 'NOTHING_TO_UPDATE'
const ALREADY_AUTHORIZED = 'ALREADY_AUTHORIZED'
const WRONG_CREDENTIALS = 'WRONG_CREDENTIALS'
const INVALID_PASSWORD_OR_EMAIL = 'INVALID_PASSWORD_OR_EMAIL'
const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR'
const NO_PERMISSIONS = 'NO_PERMISSIONS'
const FAILED_PAYMENT_REMOVAL = 'FAILED_PAYMENT_REMOVAL'
const NO_PAYMENTS_TO_MARK = 'NO_PAYMENTS_TO_MARK'
const FAILED_PAYMENT_MARKING = 'FAILED_PAYMENT_MARKING'
const FAILED_PAYMENT_CREATION = 'FAILED_PAYMENT_CREATION'
const CREATED_PAYMENT_NOT_PAID = 'CREATED_PAYMENT_NOT_PAID'
const BALANCE_NOT_UPDATED = 'BALANCE_NOT_UPDATED'
const PARTNER_HAS_ZERO_BALANCE = 'PARTNER_HAS_ZERO_BALANCE'
const INVALID_REFERRER_CODE = 'INVALID_REFERRER_CODE'

module.exports = {
  ACCESS_DENIED,
  EMAIL_ALREADY_TAKEN,
  PARTNER_NOT_FOUND,
  TOKEN_EXPIRED,
  WRONG_OLD_PASSWORD,
  PARTNER_BLOCKED,
  FAILED_TO_AUTHORIZE,
  NOTHING_TO_UPDATE,
  ALREADY_AUTHORIZED,
  WRONG_CREDENTIALS,
  INVALID_PASSWORD_OR_EMAIL,
  UNEXPECTED_ERROR,
  NO_PERMISSIONS,
  FAILED_PAYMENT_REMOVAL,
  NO_PAYMENTS_TO_MARK,
  FAILED_PAYMENT_MARKING,
  FAILED_PAYMENT_CREATION,
  CREATED_PAYMENT_NOT_PAID,
  BALANCE_NOT_UPDATED,
  PARTNER_HAS_ZERO_BALANCE,
  INVALID_REFERRER_CODE,
}
