import styled from 'styled-components'
import Text from '@kupibilet/ui/components/Typography/Text'
import mq from '@kupibilet/ui/utils/media-queries'
import carrot from './assets/carrot.svg'

const Wrapper = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
    margin-bottom: 27px;
    ${mq.mobile`
      width: 100%;
    `}
`

const CardImage = styled.img.attrs({
  src: carrot,
})`
  width: 39px;
  height: 39px;
`

const CardText = styled(Text)`
  padding-left: 20px
`

export {
  Wrapper,
  CardImage,
  CardText,
}
