// @flow
import React from 'react'
import styled from 'styled-components'
import Box from '@kupibilet/ui/components/Box'

type Props = {
  type: 'success' | 'error',
  children: React.Node,
}

// TODO: move it to UI kit
// this is our pink color that we use on kupibilet.ru inside Comments
const PINK_COLOR = '#fff2fe'

const ALERT_TYPES = {
  success: {
    color: 'textDark',
    backgroundColor: 'secondaryLightest',
    borderColor: 'secondaryLighter',
  },
  error: {
    color: 'fail',
    backgroundColor: PINK_COLOR,
    borderColor: PINK_COLOR,
  },
}

const StyledAlert = styled(Box)`
  width: 100%;
  margin-top: 24px;
  padding: 12px 21px;
  color: ${({ theme, color }) => theme.color[color] || color};
  background-color: ${({ theme, backgroundColor }) => theme.color[backgroundColor] || backgroundColor};
  border-color: ${({ theme, borderColor }) => theme.color[borderColor] || borderColor};
`

const Alert = ({ type, children } : Props) => {
  const colors = ALERT_TYPES[type]
  return (
    <StyledAlert {...colors}>
      {children}
    </StyledAlert>
  )
}

export default Alert
