import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import UILink from '@kupibilet/ui/components/Link'
import DefaultHeaderLogo from '@kupibilet/ui/components/Header/DefaultHeaderLogo'
import Dropdown, { DropdownContent } from '@kupibilet/ui/components/Dropdown'
import partnerLogo from './assets/logo-partner.svg'

export const HeaderLogo = styled(DefaultHeaderLogo)`
  background: url(${partnerLogo}) no-repeat;
  width: 171px;
`

export const FakeLink = styled(UILink)`
  display: block;
  width: 100%;
`

export const StyledLink = styled(UILink)`
  margin-right: 18px;
  ${mq.mobile`
    margin-right: 0;
  `}
  & + & {
    ${mq.mobile`
      margin-left: 12px;
    `}
  }
`

export const StyledDropdown = styled(Dropdown)`
  ${DropdownContent} {
    padding: 12px;
    min-width: 180px;
    left: auto;
  }
`

export const DropdownLink = styled(UILink)`
  margin-bottom: 12px;
  display: block;
`

export const RouterDropdownLink = styled(UILink)`
  margin-bottom: 12px;
  display: block;
`
