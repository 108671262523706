// @flow

type Props = {
  token: string,
  agentCode: string,
}

export default ({ token, agentCode }: Props) => (
  `https://helpdesk.kpb.lt/flights-orders?token=${token}&search=${agentCode}`
)
