import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import TextSmall from '@kupibilet/ui/components/Typography/TextSmall'
import Text from '@kupibilet/ui/components/Typography/Text'
import style from 'shared/utils/styleAs'

export const TableWrapper = styled.div`
  max-width: 100%;
  ${mq.handheld`
    overflow: auto;
  `}
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 18px;
`

export const Legend = styled.tr`
  border: none;
`

export const LegendCell = style(TextSmall).as('th')`
  padding: 12px;
  color: ${({ theme }) => theme.color.text};
  text-align: left;
  background: ${({ theme }) => theme.color.background};

  position: sticky;
  top: 0px;
  user-select: none;
  white-space: nowrap;
  
  // Border-bottom for sticky
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;

    background: ${({ theme }) => theme.color.miscLighter};
  }
`

export const StickyLegendCell = styled(LegendCell)`
  &::before {
    ${mq.handheld`
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background: ${({ theme }) => theme.color.miscLighter};
    `}
  }
  ${mq.handheld`
    left: 0;
    z-index: 1;
  `}
`

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.color.miscLighter};
  &:hover {
    ${({ shouldHover, theme }) => shouldHover && `
      cursor: pointer;
      background: ${theme.color.miscLightest};
    `}
  }
`

export const TableCell = style(Text).as('td')`
  padding: 12px;
  text-align: ${({ align }) => align || 'left'};
`

export const StickyTableCell = styled(TableCell)`
  &::before{
    ${mq.handheld`
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background: ${({ theme }) => theme.color.miscLighter};
    `}
  }
  ${mq.handheld`
    background: ${({ theme }) => theme.color.background};
    position: sticky;
    left: 0;
    z-index: 1;
  `}
`
