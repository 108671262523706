// @flow
import React, { PureComponent } from 'react'
import { Wrapper, CardImage, CardText } from './styled'

type Props = {
  text: string,
}

class Card extends PureComponent<Props> {
  render() {
    const { text } = this.props
    return (
      <Wrapper>
        <CardImage />
        <CardText>
          {text}
        </CardText>
      </Wrapper>
    )
  }
}

export default Card
