import styled from 'styled-components'
import {
  Legend as UILegend,
  LegendCell as UILegendCell,
  TableCell as UITableCell,
  TableRow as UITableRow,
} from 'shared/components'

const TableRow = styled(UITableRow)`
  display: flex;
`

const LegendCell = styled(UILegendCell)`
  flex-grow: ${({ flexGrow = 2 }) => flexGrow};
  flex-basis: 0;
  word-break: break-all;
`


const TableCell = styled(UITableCell)`
  flex-grow: ${({ flexGrow = 2 }) => flexGrow};
  flex-basis: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Legend = styled(UILegend)`
  display: flex;
`

export { TableRow, TableCell, LegendCell, Legend }
