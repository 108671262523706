// @flow
import React from 'react'
import styled from 'styled-components'
import Text from '@kupibilet/ui/components/Typography/Text'
import _ from 'lodash'

type Props = {
  reason: string,
}

const REASONS = {
  click: {
    text: 'Клик',
    color: 'success ',
  },
  purchase: {
    text: 'Покупка',
    color: 'success',
  },
  referral: {
    text: 'С реферала',
    color: 'secondaryLight',
  },
  admin_correction: {
    text: 'Коррекция',
    color: 'secondaryLight',
  },
  admin_sanction: {
    text: 'Аннулирование баланса',
    color: 'secondaryLight',
  },
  payment: {
    text: 'Выплата',
    color: 'fail',
  },
}

const StyledText = styled(Text)`
  color: ${({ theme, color }) => theme.color[color]};
`
const Reason = ({ reason }: Props) => (
  <StyledText color={_.get(REASONS, `${reason}.color`, null)}>
    {_.get(REASONS, `${reason}.text`, null)}
  </StyledText>
)

export default Reason
