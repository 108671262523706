import styled from 'styled-components'
import FormItem from '@kupibilet/ui/components/FormItem'

export default styled(FormItem)`
  width: 100%;
  margin-bottom: 20px;

  & & {
    padding-left: 18px;
  }
`
