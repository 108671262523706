import styled from 'styled-components'
import { Select } from '@kupibilet/ui/components/Select'
import Button from '@kupibilet/ui/components/Button'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledSelect = styled(Select)`
  height: 30px;
`

export const StyledButton = styled(Button)`
  margin-left: 8px;
`
