import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'

import reducers from './reducers'
import sagas from './sagas'

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const enhancers = composeEnhancers(
  applyMiddleware(sagaMiddleware),
)
export const store = createStore(reducers, enhancers)
export const persistor = persistStore(store)

sagaMiddleware.run(sagas)
