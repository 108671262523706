import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import { borderRadiusLarge } from '@kupibilet/ui/utils/borderRadius'
import H2 from '@kupibilet/ui/components/Typography/H2'
import TextSmall from '@kupibilet/ui/components/Typography/TextSmall'
import rich from './assets/rich.svg'

export const Wrapper = styled.div`
  padding: 18px;
  border: 1px solid ${({ theme }) => theme.color.miscLighter};
  ${borderRadiusLarge.all}
  display: flex;
  align-items: stretch;
  max-width: 336px;
  flex-grow: 1;
  justify-content: space-between;
  ${mq.handheld`
    padding: 12px;
  `}
  ${mq.mobile`
    padding: 9px;
  `}
`
export const Image = styled.div`
  background-image: url(${rich});
  min-height: 90px;
  width: 90px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 18px;
  ${mq.tablet`
    min-height: 80px;
    width: 80px;
    margin-right: 12px;
  `}
  ${mq.mobile`
    min-height: 60px;
    width: 60px;
    margin-right: 9px;
  `}
`
export const BalanceValue = styled(H2)`
  color: ${({ theme }) => theme.color.secondaryDarker};
  font-weight: 700;
  margin-bottom: 12px;
  ${mq.mobile`
    margin-bottom: 6px;
  `}
`
export const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 178px;
  margin-top: 6px;
`
export const ExtraText = styled(TextSmall)`
  color: ${({ theme }) => theme.color.text};
`
export const Currency = styled(TextSmall)`
  font-weight: 700;
`
