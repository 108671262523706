import styled from 'styled-components'
import rabbit from './assets/rabbit-with-carrot.svg'

export const RabbitWrapper = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
`

export const Rabbit = styled.img.attrs({
  src: rabbit,
})`
  width: 260px;
  height: 280px;
  position: relative;
`
