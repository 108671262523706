const convertKopeksToRubles = (kopeks) => kopeks / 100

const formatRubles = (rubles, afterComma) => {
  const formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: afterComma,
  })
  return formatter.format(rubles)
}

const formatCurrency = (kopeks, afterComma = 2) => {
  if (typeof kopeks !== 'number') return '—'
  const rubles = convertKopeksToRubles(kopeks)
  return formatRubles(rubles, afterComma)
}

export default formatCurrency
