// @flow
import React, { memo } from 'react'
import { Mutation } from 'react-apollo'
import { Formik, Form, Field } from 'formik'
import Button from '@kupibilet/ui/components/Button'
import _ from 'lodash'
import {
  FormInput,
  FormRow,
  AlertApiError,
  UnexpectedError,
  H2,
  FlexBoxContainer,
  HalfColumn,
  EditFormWrapper,
  PostbackUrlDescription,
  Alert,
} from 'shared/components'
import { EMAIL, STRING, OPTIONAL_STRING } from 'shared/validation/validators'
import { createValidationSchema } from 'shared/validation/'
import AppContext from 'partner/contexts/AppContext'
import { EDIT_MUTATION } from '../queries'

type FormProps = {
  graphQLError: string,
  editSelf: () => void,
  loading: boolean,
  email: string,
  postbackUrl: string,
  workMode: string,
  isSuccess: boolean,
}

const createFormInitialValues = (email, postbackUrl) => ({
  email,
  passwordOld: '',
  passwordNew: '',
  postbackUrl,
})

const validationSchema = createValidationSchema({
  email: EMAIL,
  passwordOld: STRING,
  passwordNew: OPTIONAL_STRING,
})

const handleFormSubmit = (editSelf, variables) => {
  editSelf({
    variables: {
      ...variables,
      email: variables.email.toLowerCase(),
    },
  })
}

const EditForm = memo(({
  graphQLError,
  editSelf,
  loading,
  email,
  postbackUrl,
  workMode,
  isSuccess,
}: FormProps) => (
  <EditFormWrapper>
    <H2>Редактировать</H2>
    <Formik
      initialValues={createFormInitialValues(email, postbackUrl)}
      validationSchema={validationSchema}
      onSubmit={(...args) => handleFormSubmit(editSelf, ...args)}
    >
      <Form>
        <FlexBoxContainer>
          <HalfColumn>
            <FormRow>
              <Field
                component={FormInput}
                name="email"
                label="Email"
              />
            </FormRow>

            <FormRow>
              <Field
                component={FormInput}
                name="passwordOld"
                label="Старый пароль"
                type="password"
              />

              <Field
                component={FormInput}
                name="passwordNew"
                label="Новый пароль"
                type="password"
              />
            </FormRow>

            {graphQLError && (
              <FormRow>
                <AlertApiError error={graphQLError} />
              </FormRow>
            )}
          </HalfColumn>

          {postbackUrl && (
            <HalfColumn>
              <FormRow>
                <Field
                  component={FormInput}
                  name="postbackUrl"
                  label="Postback Url"
                  disabled
                  isWide
                />
              </FormRow>
              <PostbackUrlDescription isCPC={workMode === 'cpc'} />
            </HalfColumn>
          )}
        </FlexBoxContainer>
        <Button size="large" type="submit" disabled={loading}>Сохранить данные</Button>

        {isSuccess && (
          <Alert type="success">Данные успешно сохранены</Alert>
        )}
      </Form>
    </Formik>
  </EditFormWrapper>
))

export default memo((props) => {
  return (
    <AppContext.Consumer>
      {(context) => (
        <Mutation
          mutation={EDIT_MUTATION}
          onCompleted={(data) => {
            const email = _.get(data, 'partner.editSelf.email')
            context.setAppState({ email })
          }}
        >
          {(editSelf, { loading, error, data }) => {
            const graphQLError = _.get(error, 'graphQLErrors[0].message', null)

            if (error && !graphQLError) {
              return <UnexpectedError />
            }

            return (
              <EditForm
                editSelf={editSelf}
                graphQLError={graphQLError}
                loading={loading}
                isSuccess={Boolean(data)}
                {...props}
              />
            )
          }}
        </Mutation>
      )}
    </AppContext.Consumer>
  )
})
