import React from 'react'
import AppContext from 'partner/contexts/AppContext'
import { Redirect } from 'react-router-dom'

function withRedirectIfNotAuthorized(Component) {
  return function WithRedirectIfNotAuthorized(props) {
    return (
      <AppContext.Consumer>
        {(appContext) => {
          if (!appContext.isLoggedIn()) {
            return <Redirect to="/partner/login" />
          }

          return <Component {...props} />
        }}
      </AppContext.Consumer>
    )
  }
}

export default withRedirectIfNotAuthorized
