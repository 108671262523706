import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'

export const Container = styled.div`
  width: 1176px;
  margin: 0 auto;
  ${mq.handheld`
    width: 100%;
    padding: 0 18px;
  `}
`
export const FlexBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${mq.handheld`
    flex-direction: column;
  `}
`
export const EditFormWrapper = styled.div`
  padding-bottom: 36px;
  border-bottom: 1px solid ${({ theme }) => theme.color.miscLighter};
  ${mq.tablet`
    padding-bottom: 24px;
  `}
  ${mq.mobile`
    padding-bottom: 18px;
  `}
`
export const HalfColumn = styled.div`
  width: 50%;
  flex-grow: 1;

  ${mq.handheld`
    width: 100%;
  `}
`
