import React from 'react'
import Comment from '@kupibilet/ui/components/CommentBubble'
import { H2 } from 'shared/components'
import {
  StyledSingleFluidSection,
} from './styled'
import { COMMENTS } from './parts/texts'


const CommentsBlock = () => (
  <StyledSingleFluidSection>
    <H2>
        Отзывы партнёров
    </H2>
    { Object.entries(COMMENTS).map(([key, value]) => (
      <Comment key={key} index={key} {...value} />
    ))
    }
  </StyledSingleFluidSection>
)

export default CommentsBlock
