// @flow
import React, { PureComponent } from 'react'
import Link from '@kupibilet/ui/components/Link'
import { Link as RouterLink } from 'react-router-dom'

import {
  Container,
  PageTitle,
  H1,
} from 'shared/components'

import {
  RabbitWrapper,
  Rabbit,
} from './styled'

type Props = {
  partnerEmail: string,
}

class SuccessfulRegistration extends PureComponent<Props> {
  render() {
    const { partnerEmail } = this.props
    return (
      <Container>
        <PageTitle>Успешная регистрация</PageTitle>
        <H1>Регистрация прошла успешно</H1>
        <p>
          {'Теперь вы можете '}
          <Link
            as={RouterLink}
            to="/partner/login"
          >
            войти на сайт
          </Link>
          {', используя адрес электронной почты '}
          <b>{ partnerEmail }</b>
        </p>
        <RabbitWrapper>
          <Rabbit />
        </RabbitWrapper>
      </Container>
    )
  }
}

export default SuccessfulRegistration
