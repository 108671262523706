// @flow
import React from 'react'
import styled from 'styled-components'
import Text from '@kupibilet/ui/components/Typography/Text'
import _ from 'lodash'

type Props = {
  status: string,
}
const STATUSES = {
  blocked: {
    text: 'Заблокирован',
    color: 'fail',
  },
  active: {
    text: 'Активен',
    color: 'success',
  },
}

const StyledText = styled(Text)`
  color: ${({ theme, color }) => theme.color[color]};
`
const Status = ({ status }: Props) => (
  <StyledText color={_.get(STATUSES, `${status}.color`, null)}>
    {_.get(STATUSES, `${status}.text`, null)}
  </StyledText>
)

export default Status
