import React from 'react'
import styled from 'styled-components'
import Text from '@kupibilet/ui/components/Typography/Text'
import mq from '@kupibilet/ui/utils/media-queries'

const Label = styled(Text)`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.textDark};
`

const Wrapper = styled.div`
  margin-bottom: 24px;
  flex-wrap: wrap;
  
  ${mq.tablet`
    display: flex;
  `}

  ${mq.mobile`
    margin-bottom: 0;
  `}
`

const FormRow = ({ label, children }) => (
  <Wrapper>
    {label && <Label as="div">{label}</Label>}
    {children}
  </Wrapper>
)

export default FormRow
