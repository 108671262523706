import gql from 'graphql-tag'

export const GET_EMAIL = gql`
  query {
    partner {
      getProfile {
        partner {
          email
          agentCode
        }
      }
    }
  }
`
