import * as Yup from 'yup'

export const EMAIL = Yup.string()
  .email('Неверный формат электронной почты')
  .required('Введите вашу электронную почту')

export const OPTIONAL_STRING = Yup.string()
  .nullable()

export const STRING = Yup.string()
  .required('Обязательное поле')

export const PERCENT = Yup.number()
  .min(0, 'Не может быть меньше нуля')
  .max(100, 'Не может быть больше ста')

export const URL = Yup.string()
  .matches(/^https?:\/\//, 'Обязательная схема http или https')
  .min(10, 'Необходимо указать полный URI')
  // TODO Fix complete validation with postback params
  // .url('Неверный формат URL')
