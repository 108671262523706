// @flow
import React, { PureComponent } from 'react'
import H5 from '@kupibilet/ui/components/Typography/H5'
import {
  LoadingWrapper,
  TextWrapper,
  Carrot,
} from './styled'

type Props = {
  text?: string,
}

type State = {
  displayLoader: boolean,
}

class Loading extends PureComponent<Props, State> {
  static defaultProps = {
    text: 'Загрузка...',
  }

  state = {
    displayLoader: false,
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({
        displayLoader: true,
      })
    }, 300)
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }

  render() {
    const { text } = this.props
    const { displayLoader } = this.state

    if (!displayLoader) {
      return null
    }

    return (
      <LoadingWrapper>
        <Carrot />
        <TextWrapper>
          <H5>{ text }</H5>
        </TextWrapper>
      </LoadingWrapper>
    )
  }
}

export default Loading
