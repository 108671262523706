import React, { createRef } from 'react'
import styled from 'styled-components'
import { Input } from '@kupibilet/ui/components/Input'

class SelectableInput extends React.PureComponent {
  inputRef = createRef()

  select = () => this.inputRef.current.select()

  render() {
    const { className, ...props } = this.props

    return (
      <div className={className}>
        <Input {...props} readonly onClick={this.select} innerRef={this.inputRef} />
      </div>
    )
  }
}

export default styled(SelectableInput)`
  width: 100%;
  flex-grow: 1;
  height: 42px;
`
