// @flow
import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'
import styled from 'styled-components'
import _ from 'lodash'
import withQueryLoading from 'shared/HOC/withQueryLoading'
import type { ApolloQuery } from 'shared/types/apollo'
import type { PartnerTypeFilterState } from 'shared/types'
import {
  Table,
  LegendCell,
  TableWrapper,
  StickyLegendCell,
  DetailedStats,
  UnexpectedError,
} from 'shared/components'
import { GROUP_BY_OPTIONS } from './consts'
import type { STATS_TYPE } from './types'
import { getQuery } from './queries'
import TableRow from './TableRow'

function getStatsDataKey(statsType: STATS_TYPE) {
  if (statsType === 'partnerStats') return 'partner.getStats'
  if (statsType === 'partnerStatsForAdmin') return 'partner.getStatsById'

  return 'admin.getSummaryStats'
}

const NoDataMessage = styled.div`
  text-align: center;
  padding: 18px;
`

type StatsTableProps = {
  partnerId: ?number,
  groupBy: string,
  uniqueUsers: Boolean,
  startDate: Date,
  endDate: Date,
  statsType: STATS_TYPE,
  partnerTypeFilter: PartnerTypeFilterState,
  agentCodesFilter: string[],
  hasAgentCodesFilter: boolean,
}

type TableRendererProps = {
  agentCodeDataKey: string,
  statsDataKey: string,
  workModeDataKey: string,
  groupBy: string,
  apolloQuery: ApolloQuery,
  startDate: string,
  endDate: string,
  statsType: STATS_TYPE,
  agentCrossales: boolean,
}

type TableRendererState = {
  modalIsOpen: boolean,
  detailedStatsGroupByValue: ?string,
}

class TableRenderer extends PureComponent<TableRendererProps, TableRendererState> {
  state = {
    modalIsOpen: false,
    detailedStatsGroupByValue: null,
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  openModal = (groupByValue) => {
    this.setState({
      modalIsOpen: true,
      detailedStatsGroupByValue: groupByValue,
    })
  }

  render() {
    const { modalIsOpen, detailedStatsGroupByValue } = this.state
    const {
      agentCodeDataKey,
      statsDataKey,
      groupBy,
      apolloQuery,
      workModeDataKey,
      startDate,
      endDate,
      statsType,
      agentCrossales,
    } = this.props
    const { data, error } = apolloQuery

    const stats = _.get(data, statsDataKey, [])

    // `error` in Query is undefined, but we have `errors` in network tab in devtools
    // So we can have `error` undefined and `stats` as null
    //
    // https://github.com/apollographql/react-apollo/issues/1546
    // https://github.com/apollographql/apollo-client/issues/3136
    if (error || stats === null) return <UnexpectedError />

    const agentCode = _.get(data, `partner.${agentCodeDataKey}`, '')
    const workMode = _.get(data, `partner.${workModeDataKey}`, 'cpc')
    const shouldRenderTableContent = stats.length > 0
    const showRefferralsRevenue = groupBy === GROUP_BY_OPTIONS.DATE
    const showCrossales = _.get(data, `partner.${agentCrossales}`, true)

    return (
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <StickyLegendCell>
                {groupBy === GROUP_BY_OPTIONS.DATE ? 'Дата' : 'Маркер'}
              </StickyLegendCell>
              <LegendCell>Визиты</LegendCell>
              <LegendCell>Поиски</LegendCell>
              <LegendCell>Выборы билетов</LegendCell>
              <LegendCell>Брони</LegendCell>
              <LegendCell>Продажи</LegendCell>
              {(workMode === 'cpa' || statsType === 'summaryStatsForAdmin') && <LegendCell>Конверсия</LegendCell>}
              <LegendCell>
                {statsType === 'summaryStatsForAdmin' ? 'Вознаграждение' : 'Доход от продаж'}
              </LegendCell>
              {(statsType === 'summaryStatsForAdmin' || groupBy === GROUP_BY_OPTIONS.DATE) && (
                <LegendCell>
                  {statsType === 'summaryStatsForAdmin'
                    ? <span>Вознаграждение<br />за рефералов</span>
                    : 'Доход по рефералам'}
                </LegendCell>
              )}
              {statsType === 'summaryStatsForAdmin' && <LegendCell>Our profit</LegendCell>}
              {statsType === 'summaryStatsForAdmin' && <LegendCell>Оборот</LegendCell>}
            </tr>
          </thead>

          {shouldRenderTableContent && (
            <tbody>
              {stats.map(
                (row) => (
                  <TableRow
                    row={row}
                    showRefferralsRevenue={showRefferralsRevenue}
                    workMode={workMode}
                    statsType={statsType}
                    openModal={this.openModal}
                  />
                ),
              )}
            </tbody>
          )}
        </Table>
        {!shouldRenderTableContent && (
          <NoDataMessage>Нет данных за выбранный период</NoDataMessage>
        )}

        <DetailedStats
          modalIsOpen={modalIsOpen}
          agentCode={agentCode}
          closeModal={this.closeModal}
          groupBy={groupBy}
          groupByValue={detailedStatsGroupByValue}
          startDate={startDate}
          endDate={endDate}
          statsType={statsType}
          showCrossales={showCrossales}
        />
      </TableWrapper>
    )
  }
}

function StatsTable(props: StatsTableProps) {
  const {
    partnerId,
    groupBy,
    uniqueUsers,
    startDate,
    endDate,
    statsType,
    partnerTypeFilter,
    agentCodesFilter,
    hasAgentCodesFilter,
  } = props

  const variables = { groupBy, uniqueUsers, startDate, endDate }

  if (partnerId) variables.partnerId = partnerId
  if (hasAgentCodesFilter) {
    variables.agentCodes = agentCodesFilter
  }
  if (partnerTypeFilter) {
    variables.partnerTypes = Object.entries(partnerTypeFilter)
      .filter(([, value]) => value)
      .map(([key]) => key)
  }

  const query = getQuery(statsType)
  const statsDataKey = getStatsDataKey(statsType)

  const agentCodeDataKey = partnerId ? 'getById.agent.code' : 'getProfile.agent.code'
  const workModeDataKey = partnerId ? 'getById.agent.workMode' : 'getProfile.agent.workMode'
  const agentCrossales = 'getProfile.agent.crossalesEnable'

  return (
    <Query query={query} variables={variables}>
      {withQueryLoading((apolloQuery) => (
        <TableRenderer
          agentCodeDataKey={agentCodeDataKey}
          statsDataKey={statsDataKey}
          workModeDataKey={workModeDataKey}
          groupBy={groupBy}
          apolloQuery={apolloQuery}
          startDate={startDate}
          endDate={endDate}
          statsType={statsType}
          agentCrossales={agentCrossales}
        />
      ))}
    </Query>
  )
}

export default StatsTable
