// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import moment from '@kupibilet/moment'
import { Select } from '@kupibilet/ui/components/Select'
import { Input } from '@kupibilet/ui/components/Input'
import { Checkbox } from '@kupibilet/ui/components/Checkbox'
import FormItem from '@kupibilet/ui/components/FormItem'
import { CheckboxFormItem } from 'shared/components'
import updateCheckboxGroup from 'shared/utils/updateCheckboxGroup'
import type { PartnerTypeFilterState } from 'shared/types'
import AgentCodesFilter from './AgentCodesFilter'
import {
  GROUP_BY_OPTIONS,
  SHOW_FOR_OPTIONS,
  KEYS_TO_LABELS,
} from './consts'


const FormRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`

const StyledFormItem = styled(FormItem)`
  & + & {
    margin-left: 12px;
  }
`

type Props = {
  groupBy: string,
  showFor: string,
  hasGroupingByTag: boolean,
  hasGroupingByAgentCode: boolean,
  uniqueUsers: boolean,
  startDate: Date,
  endDate: Date,
  updateState: ({}) => void,
  hasPartnerTypeFilter: boolean,
  hasAgentCodesFilter: boolean,
  partnerTypeFilter: PartnerTypeFilterState,
  agentCodesFilter: string[],
}

class Filters extends Component<Props> {
  onGroupBySelected = (_, { suggestion: { key } }) => {
    const { updateState } = this.props

    updateState({
      groupBy: key,
    })
  }

  onShowForSelected = (_, { suggestion: { key } }) => {
    const { updateState } = this.props

    updateState({
      showFor: key,
    })
  }

  onUniqueUsersChange = (event) => {
    const { updateState } = this.props

    updateState({
      uniqueUsers: event.target.checked,
    })
  }

  onEndDateChange = ({ target: { value: endDate } }) => {
    const { updateState, startDate } = this.props

    if (moment(endDate).subtract(2, 'months') > moment(startDate)) {
      updateState({
        startDate: moment(endDate).subtract(2, 'months').format('YYYY-MM-DD'),
        endDate,
      })
    } else {
      updateState({
        endDate,
      })
    }
  }

  onStartDateChange = ({ target: { value: startDate } }) => {
    const { updateState, endDate } = this.props

    if (moment(startDate).add(2, 'months') < moment(endDate)) {
      updateState({
        startDate,
        endDate: moment(startDate).add(2, 'months').format('YYYY-MM-DD'),
      })
    } else {
      updateState({
        startDate,
      })
    }
  }

  renderGroupBySelector = () => {
    const { groupBy, hasGroupingByTag, hasGroupingByAgentCode } = this.props
    const groupBySuggestions = [
      { value: KEYS_TO_LABELS[GROUP_BY_OPTIONS.DATE], key: GROUP_BY_OPTIONS.DATE },
    ]

    if (hasGroupingByTag) {
      groupBySuggestions.push(
        { value: KEYS_TO_LABELS[GROUP_BY_OPTIONS.MARKER], key: GROUP_BY_OPTIONS.MARKER },
      )
    }

    if (hasGroupingByAgentCode) {
      groupBySuggestions.push(
        { value: KEYS_TO_LABELS[GROUP_BY_OPTIONS.AGENT_CODE], key: GROUP_BY_OPTIONS.AGENT_CODE },
      )
    }

    if (groupBySuggestions.length === 1) return null

    return (
      <StyledFormItem label="Группировать по">
        <Select
          suggestions={groupBySuggestions}
          selectedSuggestion={{ value: KEYS_TO_LABELS[groupBy] }}
          onSuggestionSelected={this.onGroupBySelected}
          getSuggestionKey={(suggestion) => suggestion.value}
        />
      </StyledFormItem>
    )
  }

  onPartnerTypeChange = ({ target: { checked, name } }) => {
    const { updateState, partnerTypeFilter } = this.props

    updateState({
      partnerTypeFilter: updateCheckboxGroup({
        checked,
        name,
        group: partnerTypeFilter,
      }),
    })
  }

  renderPartnerTypeFilter = () => {
    const { hasPartnerTypeFilter, partnerTypeFilter } = this.props

    if (!hasPartnerTypeFilter) return null

    return (
      <CheckboxFormItem label="Тип партнёра">
        <Checkbox checked={partnerTypeFilter.all} name="all" onChange={this.onPartnerTypeChange}>
          Все
        </Checkbox>
        <Checkbox checked={partnerTypeFilter.partner} name="partner" onChange={this.onPartnerTypeChange}>
          Партнёр
        </Checkbox>
        <Checkbox checked={partnerTypeFilter.ad_network} name="ad_network" onChange={this.onPartnerTypeChange}>
          Банерная сеть
        </Checkbox>
      </CheckboxFormItem>
    )
  }

  renderAgentCodesFilter = () => {
    const { hasAgentCodesFilter, agentCodesFilter, updateState } = this.props

    if (!hasAgentCodesFilter) return null

    return (
      <StyledFormItem label="Фильтр по агентскому коду">
        <AgentCodesFilter agentCodesFilter={agentCodesFilter} updateState={updateState} />
      </StyledFormItem>
    )
  }

  render() {
    const { showFor, uniqueUsers, startDate, endDate } = this.props

    return (
      <div>
        <FormRow>
          {this.renderPartnerTypeFilter()}

          {this.renderGroupBySelector()}

          {this.renderAgentCodesFilter()}

          <StyledFormItem label="Показать за">
            <Select
              suggestions={[
                { value: KEYS_TO_LABELS[SHOW_FOR_OPTIONS.WEEK], key: SHOW_FOR_OPTIONS.WEEK },
                { value: KEYS_TO_LABELS[SHOW_FOR_OPTIONS.MONTH], key: SHOW_FOR_OPTIONS.MONTH },
                { value: KEYS_TO_LABELS[SHOW_FOR_OPTIONS.PERIOD], key: SHOW_FOR_OPTIONS.PERIOD },
              ]}
              selectedSuggestion={{ value: KEYS_TO_LABELS[showFor] }}
              onSuggestionSelected={this.onShowForSelected}
              getSuggestionKey={(suggestion) => suggestion.value}
            />
          </StyledFormItem>

          {showFor === SHOW_FOR_OPTIONS.PERIOD && (
            <>
              <StyledFormItem label="Начало периода">
                <Input
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={this.onStartDateChange}
                />
              </StyledFormItem>

              <StyledFormItem label="Конец периода">
                <Input
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={this.onEndDateChange}
                />
              </StyledFormItem>
            </>
          )}
        </FormRow>

        <FormRow>
          <Checkbox
            checked={uniqueUsers}
            onChange={this.onUniqueUsersChange}
          >
            Только уникальные пользователи
          </Checkbox>
        </FormRow>
      </div>
    )
  }
}


export default Filters
