import styled from 'styled-components'
import rabbit from './assets/pleased-rabbit.svg'

export const PleasedRabbitWrapper = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`

export const PleasedRabbit = styled.img.attrs({
  src: rabbit,
})`
  width: 260px;
  height: 280px;
  position: relative;
  top: -70px
`
