import React from 'react'
import translateApiError from 'shared/utils/translateApiError'
import Alert from './Alert'

type Props = {
  error: string,
}

const AlertApiError = ({ error } : Props) => (
  <Alert type="error">
    { translateApiError(error) }
  </Alert>
)

export default AlertApiError
