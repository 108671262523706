// @flow
import React, { PureComponent } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Header as SharedHeader } from 'shared/components'
import { LOGOUT_MUTATION } from 'partner/containers/Header/queries'
import RouterButton from 'partner/components/RouterButton'
import { trackEvent } from 'partner/utils/tracking'
import type { Location } from 'react-router-dom'
import { StyledLink } from './styled'

const trackRegistrationLinkClick = () => trackEvent('header.registration_link.click')

const links = [
  {
    title: 'Статистика',
    link: '/partner/stats',
  },
  {
    title: 'Промо-материалы',
    link: '/partner/promo',
  },
  {
    title: 'Профиль',
    link: '/partner/profile',
  },
]

const unauthorizedLinks = [
  {
    title: 'Вход',
    link: '/partner/login',
    clickHandler: null,
  },
  {
    title: 'Регистрация',
    link: '/partner/register',
    clickHandler: trackRegistrationLinkClick,
  },
]

type Props = {
  location: Location,
  email: string,
  setAppState: () => void,
  isMobile: boolean,
}

class InnerHeader extends PureComponent<Props> {
  renderButton = ({ title, link, clickHandler = null }) => (
    <RouterButton
      key={title}
      title={title}
      to={link}
      size="normal"
      clickHandler={clickHandler}
    />
  )

  renderLandingLoginLink = ({ link, title }) => {
    const { isMobile } = this.props
    const toLink = isMobile ? '/partner/register' : link

    if (!isMobile) {
      return (<StyledLink key={title} as={Link} to={toLink}>{title}</StyledLink>)
    }
    return this.renderButton({ title, link: toLink })
  }

  renderLandingUnauthorizedLinks = () => {
    const { isMobile } = this.props

    return (
      <div>
        {unauthorizedLinks
          .map(({ title, link, clickHandler }) => {
            if (link.startsWith('/partner/login')) {
              return this.renderLandingLoginLink({ title, link })
            }
            if (link.startsWith('/partner/register')) {
              return !isMobile && this.renderButton({ title, link, clickHandler })
            }
          })
        }
      </div>
    )
  }

  renderPartnerUnauthorizedLinks = () => {
    const { location: { pathname } } = this.props
    const isPartnerLogin = pathname.startsWith('/partner/login')
    const isPartnerRegister = pathname.startsWith('/partner/register')

    return (
      <div>
        {unauthorizedLinks
          .map(({ title, link }) => {
            const loginLink = link.startsWith('/partner/login')
            const registerLink = link.startsWith('/partner/register')

            if (!isPartnerLogin && loginLink) {
              return (<StyledLink key={title} as={Link} to={link}>{title}</StyledLink>)
            }
            if (!isPartnerRegister && registerLink) {
              return (
                <StyledLink key={title} as={Link} to={link} onClick={trackRegistrationLinkClick}>
                  {title}
                </StyledLink>
              )
            }
            if (!loginLink && !registerLink) {
              return (<StyledLink key={title} as={Link} to={link}>{title}</StyledLink>)
            }
          })
        }
      </div>
    )
  }

  renderUnauthorizedLinks = () => {
    const { location: { pathname } } = this.props
    const isLanding = pathname === '/'

    return isLanding ? this.renderLandingUnauthorizedLinks() : this.renderPartnerUnauthorizedLinks()
  }

  render() {
    const { email, setAppState, location: { pathname } } = this.props
    return (
      <SharedHeader
        email={email}
        setAppState={setAppState}
        logoutMutation={LOGOUT_MUTATION}
        links={pathname === '/' ? [] : links}
        renderUnauthorizedLinks={this.renderUnauthorizedLinks()}
        logoLink="/partner/profile"
        enableHelpLink
      />
    )
  }
}

export default withRouter(InnerHeader)
