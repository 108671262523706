import React from 'react'
import { connect } from 'react-redux'
import qs from 'querystring'

import WithLabel from 'shared/pages/Constructors/components/WithLabel'
import DestinationsGroup from 'shared/pages/Constructors/components/DestinationsGroup'
import DateRangeContainer from 'shared/pages/Constructors/components/DateRangeContainer'
import LinkValue from 'shared/pages/Constructors/components/LinkValue'
import DateRange from '@kupibilet/ui/components/DateRange'
import Marker from 'shared/pages/Constructors/constructors/Marker'
import AirportSelect from 'shared/pages/Constructors/components/AirportSelect'
import { objectToSearchQuery } from '@kupibilet/forms/es/utils/searchQuery'
import { Container, StyledSelect } from './styled'

const BASE_URL = 'https://kupibilet.ru'

const linkTypes = [
  { key: 'home', value: 'главную' },
  { key: 'search', value: 'поиск' },
  { key: 'directionLanding', value: 'лендинг направления' },
]

const selectInputProps = {
  id: 'linkType',
  meta: {
    active: true,
  },
}

class Link extends React.PureComponent {
  state = {
    linkType: linkTypes[0],
    focusedDatesInput: null,
    departure: null,
    arrival: null,
    departureDate: null,
    returnDate: null,
  }

  linkTypeSelected = (e, { suggestion }) => {
    this.setState({ linkType: suggestion })
  }

  onDatesFocusChange = (focusedDatesInput) => this.setState({ focusedDatesInput })

  onDatesChange = ({ startDate, endDate }) => this.setState({
    departureDate: startDate,
    returnDate: endDate,
  })

  getRefLink = () => {
    const { selectedMarker, agentCode } = this.props
    const { linkType, departureDate, returnDate, departure, arrival } = this.state

    const q = { agent: agentCode }
    if (selectedMarker) {
      q.marker = selectedMarker
    }
    const qstring = `?${qs.stringify(q)}`

    if (linkType.key === 'home') {
      return [BASE_URL, objectToSearchQuery({
        departureAirport: departure || arrival && 'XXX',
        arrivalAirport: arrival || departure && 'XXX',
        departureDate,
        flightBackDate: returnDate,
      })].join('/') + qstring
    }

    if (linkType.key === 'search') {
      if (!departure || !arrival || !departureDate) {
        return 'Необходимо заполнить вылет, прилёт и дату перелёта'
      }

      return [BASE_URL, 'search', objectToSearchQuery({
        departureAirport: departure,
        arrivalAirport: arrival,
        departureDate,
        flightBackDate: returnDate,
      })].join('/') + qstring
    }

    if (linkType.key === 'directionLanding') {
      if (!departure || !arrival) {
        return 'Необходимо заполнить вылет и прилёт'
      }

      return [BASE_URL, 'routes', departure, arrival].join('/') + qstring
    }
  }

  onDepartureSelected = ({ IATACode }) => this.setState({ departure: IATACode })

  onArrivalSelected = ({ IATACode }) => this.setState({ arrival: IATACode })

  render() {
    const { linkType, departureDate, returnDate, focusedDatesInput } = this.state

    return (
      <Container>
        <WithLabel label="Ссылка на" htmlFor="linkType">
          <StyledSelect
            suggestions={linkTypes}
            onSuggestionSelected={this.linkTypeSelected}
            selectedSuggestion={linkType}
            inputProps={selectInputProps}
            size="small"
          />
        </WithLabel>

        <Marker />

        <WithLabel label="Направление перелёта" htmlFor="">
          <DestinationsGroup>
            <AirportSelect onSelected={this.onDepartureSelected} inputProps={{ placeholder: 'Вылет из' }} />
            <AirportSelect onSelected={this.onArrivalSelected} inputProps={{ placeholder: 'Прилёт в' }} />
          </DestinationsGroup>
        </WithLabel>

        { ['home', 'search'].includes(linkType.key) &&
          <WithLabel label="Дата перелёта" htmlFor="">
            <DateRangeContainer>
              <DateRange
                startDate={departureDate}
                endDate={returnDate}
                focusedInput={focusedDatesInput}
                onFocusChange={this.onDatesFocusChange}
                onDatesChange={this.onDatesChange}
              />
            </DateRangeContainer>
          </WithLabel>
        }

        <WithLabel label="Рекламная ссылка" htmlFor="refLink">
          <LinkValue value={this.getRefLink()} id="refLink" />
        </WithLabel>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedMarker: state.markers.selectedMarker,
})

const withConnect = connect(mapStateToProps)

export default withConnect(Link)
