// @flow
import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import compose from 'lodash/fp/compose'

import withResponsiveTheme from 'shared/HOC/withResponsiveTheme'

import AppContext from 'partner/contexts/AppContext'

import Profile from 'partner/pages/Profile'
import Login from 'partner/pages/Login'
import Registration from 'partner/pages/Registration'
import Stats from 'partner/pages/Stats'
import Constructors from 'partner/pages/Constructors'
import Landing from 'partner/pages/Landing'

import Header from 'partner/containers/Header'
import Footer from 'partner/containers/Footer'

import withApollo from 'partner/HOC/withApollo'

import injectIntercomWidget from 'partner/utils/injectIntercomWidget'

const BASE_PATH = '/partner'

type Props = {
  email: string,
  agentCode: string,
}

type State = {
  email: string,
}

class App extends Component<Props, State> {
  constructor(props) {
    super(props)

    // Initial email is fetched from GraphQL
    const { email } = props
    this.state = {
      email,
    }
  }

  componentDidMount() {
    const { email } = this.state
    injectIntercomWidget(email)
  }

  isLoggedIn = () => {
    const { email } = this.state
    return Boolean(email)
  }

  setAppState = (...args) => this.setState(...args)

  render() {
    const { email } = this.state
    const { agentCode } = this.props

    const contextValue = {
      email,
      isLoggedIn: this.isLoggedIn,
      setAppState: this.setAppState,
    }

    return (
      <AppContext.Provider value={contextValue}>
        <Router>
          <>
            <Header />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path={`${BASE_PATH}/profile`} component={Profile} />
              <Route exact path={`${BASE_PATH}/stats`} component={Stats} />
              <Route exact path={`${BASE_PATH}/login`} component={Login} />
              <Route exact path={`${BASE_PATH}/register`} component={Registration} />
              <Route
                exact
                path={`${BASE_PATH}/promo`}
                render={() => <Constructors agentCode={agentCode} />}
              />

              { /* 404 */ }
              <Redirect to={`${BASE_PATH}/login`} />
            </Switch>
            <Footer />
          </>
        </Router>
      </AppContext.Provider>
    )
  }
}

export default compose(
  withResponsiveTheme,
  withApollo,
)(App)
