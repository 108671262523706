import injectScript from './injectScript'

const GA_MEASUREMENT_ID = 'UA-144572712-1'
const isProd = process.env.NODE_ENV === 'production'
let scriptInjectingPromise

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments)
}

function initScript() {
  if (scriptInjectingPromise) {
    return scriptInjectingPromise
  }

  if (!isProd) {
    window.dataLayer = []
    scriptInjectingPromise = Promise.resolve()
    return scriptInjectingPromise
  }

  window.dataLayer = window.dataLayer || []
  gtag('js', new Date())
  gtag('config', GA_MEASUREMENT_ID)

  scriptInjectingPromise = injectScript(`//www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`)
    .catch((err) => {
      console.warn('[GA] Failed to inject script', err)
      throw err
    })

  return scriptInjectingPromise
}

export async function gaSendEvent(name) {
  try {
    console.log('[GA] Send event', name)
    await initScript()
    gtag('event', name)
  } catch (err) {
    console.warn('[GA] Failed to send event', err)
  }
}
