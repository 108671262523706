// @flow
import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'
import _ from 'lodash'
import styled from 'styled-components'
import Autocomplete from '@kupibilet/ui/components/Autocomplete'
import { SuggestionsContainer } from '@kupibilet/ui/components/Autocomplete/styled'
import Suggestion from '@kupibilet/ui/components/Suggestion'
import { Input } from '@kupibilet/ui/components/Input'
import type { ApolloQuery } from 'shared/types/apollo'
import { UnexpectedError } from 'shared/components'
import { GET_ALL_AGENT_CODES } from './queries'

// TODO: Remove this if scrolling behaviour will be added to SuggestionsContainer by default
const StyledSuggestionsContainer = styled(SuggestionsContainer)`
  max-height: 350px;
  overflow-y: auto;
`

function normalizeSuggestions(suggestions) {
  return suggestions.map((agentCode) => ({ key: agentCode, value: agentCode }))
}

function buildNextAgentCodesFilter({ value, agentCodesFilter }) {
  return agentCodesFilter.includes(value)
    ? agentCodesFilter.filter((agentCode) => agentCode !== value)
    : [...agentCodesFilter, value]
}

type Props = {
  updateState: ({}) => void,
  agentCodesList: string[],
}

type State = {
  filterValue: string,
  suggestions: string[],
  agentCodesFilter: string[],
}

class AgentCodesFilter extends PureComponent<Props, State> {
  state = {
    filterValue: '',
    suggestions: [],
    agentCodesFilter: [],
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    const { agentCodesFilter } = this.state
    const { updateState, agentCodesList } = this.props

    const isSuggestSelection = reason === 'suggestion-selected'
    const normalizedValue = value.trim().toLowerCase()

    const nextAgentCodesFilter = isSuggestSelection
      ? buildNextAgentCodesFilter({ value, agentCodesFilter })
      : agentCodesFilter

    const filteredAgentCodes = agentCodesList
      .filter((agentCode) => {
        if (nextAgentCodesFilter.includes(agentCode)) return false
        if (isSuggestSelection) return true

        return agentCode.toLowerCase().startsWith(normalizedValue)
      })

    const selectedFilteredAgents = nextAgentCodesFilter
      .filter((agentCode) => {
        if (isSuggestSelection) return true

        return agentCode.toLowerCase().startsWith(normalizedValue)
      })


    if (isSuggestSelection) {
      updateState({ agentCodesFilter: nextAgentCodesFilter })
    }

    this.setState({
      filterValue: isSuggestSelection ? '' : value,
      agentCodesFilter: nextAgentCodesFilter,
      suggestions: [...selectedFilteredAgents, ...filteredAgentCodes],
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  }

  onChange = (event, { method }) => {
    if (method === 'autoSuggest') return

    const { target: { value } } = event

    this.setState({
      filterValue: value,
    })
  }

  render() {
    const { filterValue, suggestions, agentCodesFilter } = this.state

    return (
      <Autocomplete
        inputProps={{
          placeholder: `Выбрано агентов: ${agentCodesFilter.length}`,
          value: filterValue,
          onChange: this.onChange,
        }}
        alwaysRenderSuggestions
        forceSuggestedValue={false}
        suggestions={normalizeSuggestions(suggestions)}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={() => true}
        renderSuggestion={(suggestion) => {
          if (agentCodesFilter.includes(suggestion.value)) {
            // Suggestion was not designed to support multi selections,
            // so just pass selectedKey equal to current suggestion key
            return <Suggestion suggestion={suggestion} selectedKey={suggestion.key} />
          }

          return <Suggestion suggestion={suggestion} />
        }}
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <StyledSuggestionsContainer query={query} {...containerProps}>
            {children}
          </StyledSuggestionsContainer>
        )}
        renderInputComponent={({ ref, ...props }) => <Input innerRef={ref} {...props} />}
      />
    )
  }
}

export default (props: Props) => (
  <Query query={GET_ALL_AGENT_CODES}>
    {({ data, error }: ApolloQuery) => {
      if (error) return <UnexpectedError />

      const partnersList = _.get(data, 'partner.getAll', [])

      const agentCodes = []
      for (const { partner: { agentCode } } of partnersList) {
        if (agentCode) {
          agentCodes.push(agentCode)
        }
      }

      return <AgentCodesFilter {...props} agentCodesList={agentCodes} />
    }}
  </Query>
)
