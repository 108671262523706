import React from 'react'
import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import { borderRadiusSmall } from '@kupibilet/ui/utils/borderRadius'
import Icon from '@kupibilet/ui/components/Icon'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.secondaryLightest};
  ${borderRadiusSmall.all}
  padding: 6px 12px 9px 9px;
  display: inline-flex;
  align-items: center;
  min-width: 714px;
  margin-top: 6px;
  ${mq.handheld`
    min-width: auto;
  `}
`
const IconWrapper = styled.div`
  margin-right: 9px;
  margin-top: 3px;
  display: flex;
  align-items: center;
`

const Attention = ({ children }) => (
  <Wrapper>
    <IconWrapper>
      <Icon
        name="warning"
        size="normal"
        fill="fail"
      />
    </IconWrapper>
    {children}
  </Wrapper>
)

export default Attention
