import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import Link from '@kupibilet/ui/components/Link'
import Text from '@kupibilet/ui/components/Typography/Text'
import FormItem from '@kupibilet/ui/components/FormItem'

export const TableWrapper = styled.div`
  max-width: 336px;
  flex-grow: 1;
  width: 100%;
`

export const StyledLink = styled(Link)`
  margin: 18px 0;
  display: block;
`

export const InviteWrapper = styled.div`
  flex-grow: 1;
  margin-left: 126px;
  ${mq.handheld`
    margin-left: 0;
  `}
`

export const Description = styled(Text)`
  margin-bottom: 24px;
`

export const StyledFormItem = styled(FormItem)`
  margin-top: 18px;
  max-width: 420px;
`
