export const GROUP_BY_OPTIONS = {
  DATE: 'date',
  MARKER: 'marker',
  AGENT_CODE: 'agent',
}

export const SHOW_FOR_OPTIONS = {
  WEEK: 'week',
  MONTH: 'month',
  PERIOD: 'period',
}

export const KEYS_TO_LABELS = {
  [GROUP_BY_OPTIONS.DATE]: 'Дате',
  [GROUP_BY_OPTIONS.MARKER]: 'Маркеру',
  [GROUP_BY_OPTIONS.AGENT_CODE]: 'Агентскому коду',
  [SHOW_FOR_OPTIONS.WEEK]: 'Неделю',
  [SHOW_FOR_OPTIONS.MONTH]: 'Месяц',
  [SHOW_FOR_OPTIONS.PERIOD]: 'Период',
}
