import { takeEvery, put } from 'redux-saga/effects'

import { Types, Creators } from './ducks'

function* markerSelected(action) {
  yield put(Creators.createMarkerSuccess(action.marker))
}

export default function* () {
  yield takeEvery(Types.MARKER_SELECTED, markerSelected)
}
