import React from 'react'
import _ from 'lodash'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider, Query } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

import { ACCESS_DENIED } from 'consts/apiErrors'

import withQueryLoading from 'shared/HOC/withQueryLoading'

import { GET_EMAIL } from './queries'

const cache = new InMemoryCache()
const link = new HttpLink()

const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
})

const withApollo = (Component) => (props) => (
  <ApolloProvider client={client}>
    <Query query={GET_EMAIL}>
      {withQueryLoading(({ error, data }) => {
        if (error) {
          const graphQLError = _.get(error, 'graphQLErrors[0].message', null)
          // TODO: handle ACCESS_DENIED nicely
          if (graphQLError === ACCESS_DENIED) {
            console.log('USER IS NOT LOGGED IN')
          } else {
            console.error(error)
            return <p>Error</p>
          }
        }

        const email = _.get(data, 'partner.getProfile.partner.email', null)
        const agentCode = _.get(data, 'partner.getProfile.partner.agentCode', null)

        return <Component email={email} agentCode={agentCode} {...props} />
      })}
    </Query>
  </ApolloProvider>
)

export default withApollo
