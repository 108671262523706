// @flow
import React, { PureComponent } from 'react'
import type { FieldAttributes } from 'formik'
import { Checkbox } from '@kupibilet/ui/components/Checkbox'

type Props = FieldAttributes & {
  text: string,
  onValueChange: () => void,
}

class FormCheckbox extends PureComponent<Props> {
  defaultProps ={
    onValueChange: () => null,
  }

  onCheckboxChange = (e: Event) => {
    const {
      field: {
        name,
      },
      form: {
        setFieldValue,
      },
      onValueChange,
    } = this.props
    setFieldValue(name, e.target.checked)
    onValueChange(e.target.checked)
  }

  render() {
    const {
      text,
      field: {
        value,
        name,
      },
      ...props
    } = this.props

    return (
      <Checkbox
        {...props}
        name={name}
        checked={value}
        onChange={this.onCheckboxChange}
      >
        {text}
      </Checkbox>
    )
  }
}

export default FormCheckbox
