import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  markerSelected: ['marker'],
  createMarker: ['marker'],
  createMarkerSuccess: null,
  deleteMarker: ['marker'],
  deleteMarkerSuccess: null,
}, { prefix: 'PARTNER/' })

export const INITIAL_STATE = {
  selectedMarker: null,
  markers: [''],
}

export default createReducer(INITIAL_STATE, {
  [Types.MARKER_SELECTED]: (state, action) => ({
    ...state,
    selectedMarker: action.marker,
  }),

  [Types.CREATE_MARKER]: (state, action) => ({
    ...state,
    markers: [...state.markers, action.marker],
    selectedMarker: action.marker,
  }),

  [Types.DELETE_MARKER]: (state, action) => ({
    ...state,
    markers: state.markers.filter((marker) => marker !== action.marker),
    selectedMarker: null,
  }),

})
