// @flow
import React from 'react'
import Button from '@kupibilet/ui/components/Button'
import { Formik, Form, Field } from 'formik'

import { FormInput, FormSelect, FormRow, AlertApiError } from 'shared/components'

import { EMAIL, STRING, OPTIONAL_STRING } from 'shared/validation/validators'
import { createValidationSchema } from 'shared/validation/'
import {
  WALLET_TYPES,
} from 'shared/consts'

type Props = {
  graphQLError: string | null,
  loading: Boolean,
  register: () => void,
}

const FORM_INITIAL_VALUES = {
  name: '',
  email: '',
  password: '',
  walletType: 'webmoney',
  walletNumber: '',
  comment: '',
}

const validationSchema = createValidationSchema({
  name: STRING,
  email: EMAIL,
  password: STRING,
  walletType: STRING,
  walletNumber: STRING,
  comment: OPTIONAL_STRING,
})

const handleFormSubmit = (register, variables) => {
  register({
    variables: {
      ...variables,
      email: variables.email.toLowerCase(),
    },
  })
}

const RegistrationForm = ({ graphQLError, register, loading }: Props) => (
  <Formik
    initialValues={FORM_INITIAL_VALUES}
    validationSchema={validationSchema}
    onSubmit={(...args) => handleFormSubmit(register, ...args)}
  >
    <Form>
      <FormRow>
        <Field
          component={FormInput}
          name="name"
          label="Имя"
        />
        <Field
          component={FormInput}
          name="email"
          label="Email"
        />
        <Field
          component={FormInput}
          name="password"
          type="password"
          label="Пароль"
        />
      </FormRow>

      <FormRow>
        <Field
          component={FormSelect}
          name="walletType"
          label="Тип кошелька"
          options={WALLET_TYPES}
        />
        <Field
          component={FormInput}
          name="walletNumber"
          label="Номер кошелька"
        />
      </FormRow>

      <FormRow>
        <Field
          component={FormInput}
          name="comment"
          label="О себе"
          placeholder="Как собираетесь сотрудничать?"
          isTextarea
        />
      </FormRow>

      {graphQLError && (
        <FormRow>
          <AlertApiError error={graphQLError} />
        </FormRow>
      )}

      <Button size="large" type="submit" disabled={loading}>
        Зарегистрироваться
      </Button>
    </Form>
  </Formik>
)

export default RegistrationForm
