import styled from 'styled-components'
import carrot from './assets/carrot.gif'

export const LoadingWrapper = styled.div`
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextWrapper = styled.div`
  margin-left: 6px;
  height: 42px;
  display: flex;
  align-items: center;
  font-weight: 600;
`

export const Carrot = styled.div`
  display: inline-block;
  width: 42px;
  height: 42px;
  background: url(${carrot}) no-repeat center;
  background-size: contain;
`
