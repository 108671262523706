import styled from 'styled-components'
import { Container } from 'shared/components'
import Link from '@kupibilet/ui/components/Link'
import mq from '@kupibilet/ui/utils/media-queries'

export const StyledLink = styled(Link)`
  & + & {
    margin-left: 18px;
    ${mq.tablet`
      margin-left: 12px;
    `}
    ${mq.mobile`
      margin-left: 0;
      margin-top: 12px;
    `}
  }
`
export const FooterContainer = styled(Container)`
  margin-top: ${({ isLanding }) => (isLanding ? '0' : '60px')};
  ${mq.tablet`
    margin-top: 54px;
  `}
  ${mq.mobile`
    margin-top: 36px;
  `}
`
export const FooterWrapper = styled.div`
  border-top: ${({ isLanding, theme }) => (isLanding
    ? 'none'
    : `1px solid ${theme.color.miscLighter}`)};
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mq.tablet`
    padding: 24px 0;
    flex-direction: column;
  `}
  ${mq.mobile`
    flex-direction: column;
    align-items: flex-start;
    padding: 18px 0;
  `}
`
export const LinkContainer = styled.div`
  ${mq.handheld`
    margin-top: 18px;
  `}
  ${mq.mobile`
    display: flex;
    flex-direction: column;
  `}

`
