const COMMENTS = [
  {
    text: 'Отличный проект и команда настоящих профи, которые всегда открыты к диалогу и готовы реализовывать новый функционал, чтобы повысить конверсию. Мы используем различные партнерские программы в области поиска и продажи авиабилетов для самостоятельных путешественников, поэтому есть с чем сравнивать. Наш вердикт: отличные цены на билеты для конечного потребителя (что хорошо отражается на конверсии), отзывчивый саппорт, выплаты всегда без задержек и в полном объеме. В общем только положительные впечатления.',
    name: 'TravelAsk.ru',
  },
  {
    text: 'Работаю с Зайцем уже несколько лет, полет отличный. Сам также покупаю билеты здесь по своей рефке. Для меня основной показатель – это конечный заработок, и партнерские программы выстраиваю именно в этом порядке, но всегда шикарный бонус, если получается выстроить дружеские отношения с ПП, с Зайцем именно такая ситуация. Ребята никогда не подводили, и даже в случае косяков, всегда их признавали и исправляли. Респект и уважуха.',
    name: 'sisers, арбитражник',
  },
  {
    text: 'Отличный конверт на купонном траффике. При прочих равных с KUPIBILET.RU получается больше комиссия и общий заработок, хотя тревел тематика на сайте ни в топе категорий, по которым идет основной оборот.',
    name: 'promkod.ru',
  },
]

export {
  COMMENTS,
}
