import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation LoginPartner($email: EmailAddress!, $password: String!) {
    partner {
      login(input: {
        email: $email,
        password: $password,
      }) {
        partner {
          email
        }
      }
    }
  }
`
