import React, { Component } from 'react'

class UnexpectedError extends Component {
  render() {
    return (
      <div>Unexpected Error happend ¯\_(ツ)_/¯</div>
    )
  }
}

export default UnexpectedError
