import React from 'react'

const AppContext = React.createContext({
  // This fields are placed here just for reference.
  // The actual context value is set in App component.
  email: null,
  isLoggedIn: () => false,
  setAppState: () => {},
})

export default AppContext
