import React from 'react'
import RouterButton from 'partner/components/RouterButton'
import { trackEvent } from 'partner/utils/tracking'

export default (props) => (
  <RouterButton
    title="Стать партнёром"
    to="/partner/register"
    clickHandler={() => trackEvent('landing.registration_link.click')}
    {...props}
  />
)
