// @flow
import React from 'react'
import formatCurrency from 'shared/utils/formatCurrency'
import { Wrapper, Image, BalanceValue, Currency, ExtraText, BalanceRow } from './styled'

type Props = {
  currentBalance: number,
  lifetimeRevenue: number,
  lifetimePaid: number,
}

const Balance = ({ currentBalance, lifetimeRevenue, lifetimePaid }: Props) => (
  <Wrapper>
    <Image />
    <div>
      <BalanceValue>
        {formatCurrency(currentBalance)}
      </BalanceValue>
      <BalanceRow>
        <ExtraText>
          Всего заработано
        </ExtraText>
        <Currency>
          {formatCurrency(lifetimeRevenue, 0)}
        </Currency>
      </BalanceRow>
      <BalanceRow>
        <ExtraText>
          Выплачено
        </ExtraText>
        <Currency>
          {formatCurrency(lifetimePaid, 0)}
        </Currency>
      </BalanceRow>
    </div>
  </Wrapper>
)

export default Balance
