import styled from 'styled-components'
import Text from '@kupibilet/ui/components/Typography/Text'

export const Wrapper = styled.div`
  display: flex;
  max-width: 756px;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: flex-start;
`
export const Row = styled.div`
  margin-bottom: 18px;
  display: flex;
  flex-grow: 1;
  flex-basis: 378px;
`
export const Key = styled(Text)`
  display: block;
  flex-grow: 1;
  max-width: 168px;
  color: ${({ theme }) => theme.color.text};
`
