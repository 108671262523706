import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import WithLabel from 'shared/pages/Constructors/components/WithLabel'
import DestinationsGroup from 'shared/pages/Constructors/components/DestinationsGroup'
import DateRangeContainer from 'shared/pages/Constructors/components/DateRangeContainer'
import Tip from 'shared/pages/Constructors/components/Tip'
import DateRange from '@kupibilet/ui/components/DateRange'
import { Checkbox } from '@kupibilet/ui/components/Checkbox'
import Marker from 'shared/pages/Constructors/constructors/Marker'
import AirportSelect from 'shared/pages/Constructors/components/AirportSelect'
import { Container, StyledSelect, StyledInput, StyledLinkValue } from './styled'

const dimensions = [
  { key: '320/503', value: 'Вертикальная форма' },
  { key: '600/500', value: 'Полу-вертикальная форма' },
  { key: '1000/400', value: 'Горизонтальная форма' },
]

class SearchFormConstructor extends React.PureComponent {
  state = {
    showHotelsTab: false,
    showTrackingRedirect: false,
    trackingRedirectURL: '',
    originalUrlParam: '',
    focusedDatesInput: null,
    departure: null,
    arrival: null,
    departureDate: null,
    returnDate: null,
    dimension: dimensions[0],
    selector: '',
  }

  componentDidMount() {
    const script = window.document.createElement('script')
    script.async = true
    script.src = 'https://widgets.kupibilet.ru/form/1/'
    window.document.head.appendChild(script)

    script.onload = this.reinjectWidget
  }

  componentDidUpdate() {
    this.reinjectWidget()
  }

  onDimensionSelected = (e, { suggestion }) => this.setState({ dimension: suggestion })

  onDatesFocusChange = (focusedDatesInput) => this.setState({ focusedDatesInput })

  onDatesChange = ({ startDate, endDate }) => this.setState({
    departureDate: startDate,
    returnDate: endDate,
  })

  _getWidgetOpts = () => {
    const { selectedMarker, agentCode } = this.props
    const {
      showHotelsTab,
      departureDate,
      returnDate,
      dimension,
      selector,
      departure,
      arrival,
      showTrackingRedirect,
      trackingRedirectURL,
      originalUrlParam,
    } = this.state

    const q = { agent: agentCode }
    if (selectedMarker) {
      q.marker = selectedMarker
    }
    const [width, height] = dimension.key.split('/')
    const trackingRedirect = showTrackingRedirect && trackingRedirectURL && originalUrlParam && {
      url: trackingRedirectURL,
      originalUrlParam,
    }

    return _.pickBy({
      q,
      showHotelsTab,
      node: selector,
      width,
      height,
      departure,
      arrival,
      departureDate: departureDate && departureDate.clone().locale('en').format('DDMMM').toUpperCase(),
      returnDate: returnDate && returnDate.clone().locale('en').format('DDMMM').toUpperCase(),
      trackingRedirect,
    }, Boolean)
  }

  /* eslint-disable react/sort-comp */
  reinjectWidget = _.debounce(() => {
    const node = document.getElementById('playground')
    Array.from(node.children).forEach((child) => child.remove())

    try {
      window.Kupibilet.widget({
        ...this._getWidgetOpts(),
        node,
      })
    } catch (e) { console.warn(e) }
  }, 1000)

  getRefLink = () => {
    const opts = this._getWidgetOpts()

    return `
  <script src="https://widgets.kupibilet.ru/form/1" type="text/javascript"></script>
  <script>window.Kupibilet.widget(${JSON.stringify(opts)})</script>
    `.replace(/^\n|\n$/, '')
  }

  onDepartureSelected = ({ IATACode }) => this.setState({ departure: IATACode })

  onArrivalSelected = ({ IATACode }) => this.setState({ arrival: IATACode })

  onHotelsToggle = () => {
    const { showHotelsTab } = this.state
    this.setState({ showHotelsTab: !showHotelsTab })
  }

  onTrackingRedirectToggle = () => {
    const { showTrackingRedirect } = this.state
    this.setState({ showTrackingRedirect: !showTrackingRedirect })
  }

  onSelectorChange = ({ target }) => this.setState({ selector: target.value.trim() })

  onRedirectURLChange = ({ target }) => this.setState({ trackingRedirectURL: target.value })

  onOriginalUrlParamChange = ({ target }) => this.setState({ originalUrlParam: target.value })

  render() {
    const {
      showHotelsTab,
      showTrackingRedirect,
      trackingRedirectURL,
      originalUrlParam,
      departureDate,
      returnDate,
      focusedDatesInput,
      dimension,
      selector,
    } = this.state

    return (
      <Container>

        <Marker />

        <WithLabel label="Направление перелёта">
          <DestinationsGroup>
            <AirportSelect onSelected={this.onDepartureSelected} inputProps={{ placeholder: 'Вылет из' }} />
            <AirportSelect onSelected={this.onArrivalSelected} inputProps={{ placeholder: 'Прилёт в' }} />
          </DestinationsGroup>
        </WithLabel>

        <WithLabel label="Дата перелёта">
          <DateRangeContainer>
            <DateRange
              startDate={departureDate}
              endDate={returnDate}
              focusedInput={focusedDatesInput}
              onFocusChange={this.onDatesFocusChange}
              onDatesChange={this.onDatesChange}
            />
          </DateRangeContainer>
        </WithLabel>

        <WithLabel label="Размер">
          <StyledSelect
            suggestions={dimensions}
            onSuggestionSelected={this.onDimensionSelected}
            selectedSuggestion={dimension}
            inputProps={{ meta: { active: true } }}
            size="small"
          />
        </WithLabel>

        <WithLabel>
          <Checkbox checked={showHotelsTab} onChange={this.onHotelsToggle}>
            Показать вкладку отелей
          </Checkbox>
        </WithLabel>

        <WithLabel>
          <Checkbox checked={showTrackingRedirect} onChange={this.onTrackingRedirectToggle}>
            Отслеживание переходов с помощью редиректа
          </Checkbox>
          { showTrackingRedirect &&
            <Fragment>
              <Tip>
                Вы можете использовать свою страницу
                для отслеживания переходов на своей стороне.<br />
                Например у вас есть страница <code>https://abc.com/count</code><br />
                Все переходы с форма могут быть направлены на эту страницу,
                чтобы посчитать их количество, но ваша страница
                &nbsp;<b>обязательно</b> должна сделать переадресацию на исходный URL,
                на который изначально указывала форма.<br />
                Мы передадим его вашей странице в параметре, например <code>redir</code><br />
                Это значит что назаж кнопку найти пользователь
                перейдёт на страницу <code>https://abc.com/count?redir=https://kupibilet.ru/search/</code>
                и будет моментально переадресован на <code>https://kupibilet.ru/search/</code>
              </Tip>

              <WithLabel label="Полный URL страницы отслеживания">
                <StyledInput
                  value={trackingRedirectURL}
                  onChange={this.onRedirectURLChange}
                  placeholder="https://www.google.com/search"
                />
              </WithLabel>

              <WithLabel label="Название параметра с исходным URL">
                <StyledInput
                  value={originalUrlParam}
                  onChange={this.onOriginalUrlParamChange}
                  placeholder="q"
                />
              </WithLabel>
              <Tip>
                  Проверьте работу редиректа нажав кнопку &quot;Найти&quot; в превью виджета ниже
              </Tip>
            </Fragment>
          }
        </WithLabel>

        <WithLabel label="Селектор контейнера в который вставится виджет">
          <StyledInput value={selector} onChange={this.onSelectorChange} placeholder="#kupibilet-widget" />
        </WithLabel>

        <WithLabel label="Код для вставки" htmlFor="refLink">
          <Tip>
            {
              !selector
                ? 'Вставьте этот код на сайт в то место, где должен появиться виджет'
                : <span>Вставьте этот код перед закрывающимся тегом <code>{ '</body>' }</code></span>
            }
          </Tip>
          <StyledLinkValue isTextarea value={this.getRefLink()} id="refLink" />
        </WithLabel>

        <WithLabel label="Демо" htmlFor="refLink">
          <div id="playground" />
        </WithLabel>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedMarker: state.markers.selectedMarker,
})

const withConnect = connect(mapStateToProps)

export default withConnect(SearchFormConstructor)
