// @flow
import gql from 'graphql-tag'
import type { STATS_TYPE } from './types'

const returnFields = `
  {
    groupBy
    visits
    searches
    created
    reserved
    issued
    revenue
    referralsRevenue
    purchasesConversion
    profit
    orderTotal
  }
`

const GET_STATS_QUERY = gql`
  query GetStats(
    $groupBy: STATS_GROUPING_OPTIONS!,
    $uniqueUsers: Boolean!,
    $startDate: DateTime!,
    $endDate: DateTime!
  ) {
    partner {
      getStats(input: {
        groupBy: $groupBy,
        startDate: $startDate,
        endDate: $endDate,
        uniqueUsers: $uniqueUsers
      }) ${returnFields}
      
      getProfile {
        agent {
          code
          workMode
          crossalesEnable
        }
      }
    }
  }
`

const GET_STATS_BY_ID_QUERY = gql`
  query GetStatsById(
    $partnerId: ID!,
    $groupBy: STATS_GROUPING_OPTIONS!,
    $uniqueUsers: Boolean!,
    $startDate: DateTime!,
    $endDate: DateTime!
  ) {
    partner {
      getStatsById(input: {
        partnerId: $partnerId,
        groupBy: $groupBy,
        startDate: $startDate,
        endDate: $endDate,
        uniqueUsers: $uniqueUsers
      }) ${returnFields}
      
      getById(id: $partnerId) {
        agent {
          code
          workMode
        }
      }
    }
  }
`

const GET_SUMMARY_STATS_QUERY = gql`
  query GetSummaryStats(
    $groupBy: STATS_GROUPING_OPTIONS!,
    $uniqueUsers: Boolean!,
    $startDate: DateTime!,
    $endDate: DateTime!,
    $partnerTypes: [AGENT_TYPES]!,
    $agentCodes: [String]!
  ) {
    admin {
      getSummaryStats(input: {
        groupBy: $groupBy,
        startDate: $startDate,
        endDate: $endDate,
        uniqueUsers: $uniqueUsers,
        partnerTypes: $partnerTypes,
        agentCodes: $agentCodes
      }) ${returnFields}
    }
  }
`

export const GET_ALL_AGENT_CODES = gql`
  query GetAllAgentCodes {
    partner {
      getAll {
        partner {
          agentCode
        }
      }
    }
  }
`

export function getQuery(statsType: STATS_TYPE) {
  if (statsType === 'partnerStats') return GET_STATS_QUERY
  if (statsType === 'partnerStatsForAdmin') return GET_STATS_BY_ID_QUERY

  return GET_SUMMARY_STATS_QUERY
}
