import React from 'react'
import { H2 } from 'shared/components'
import { Subtitle, StyledSingleFluidSection, StyledSearchForm } from './styled'

const ProgramTools = () => (
  <StyledSingleFluidSection>
    <H2>
        Инструменты программы
    </H2>
    <Subtitle as="p">
        У нас есть текстовые ссылки, виджеты и баннеры.
        Размещайте разные промо-материалы на своём сайте и зарабатывайте больше.
    </Subtitle>
    <StyledSearchForm />
  </StyledSingleFluidSection>
)

export default ProgramTools
