// @flow
import React from 'react'
import { PageTitle, H1 } from 'shared/components'
import {
  Image,
  FlexContainer,
  LeftSide,
  RightSide,
} from './styled'

type Props = {
  title?: string,
  renderDescription?: () => React.Node,
}

const NotFound = ({ title, renderDescription } : Props) => (
  <FlexContainer>
    <LeftSide>
      <PageTitle>{ title }</PageTitle>
      <H1>{ title }</H1>
      { renderDescription() }
    </LeftSide>
    <RightSide>
      <Image />
    </RightSide>
  </FlexContainer>
)

NotFound.defaultProps = {
  title: 'Страница не найдена',
  renderDescription: () => null,
}

export default NotFound
