// @flow
import React from 'react'
import withRouter from 'react-router-dom/withRouter'
import type { Location } from 'react-router-dom'
import Button from '@kupibilet/ui/components/Button'
import { FooterContainer, FooterWrapper, StyledLink, LinkContainer } from './styled'

type Props = {
  location: Location,
}
const links = [
  {
    title: 'О нас',
    link: 'https://help-with.kupibilet.ru/%D0%BF%D1%80%D0%BE-kupibilet',
  },
  {
    title: 'Правила',
    link: 'https://help-with.kupibilet.ru/ru/articles/2812272',
  },
  {
    title: 'Реферальная система',
    link: 'https://help-with.kupibilet.ru/ru/articles/2811800',
  },
  {
    title: 'Вопросы',
    link: 'https://help-with.kupibilet.ru/ru/articles/2812005',
  },
]

const openHelpDialog = () => {
  if (window.Intercom) {
    window.Intercom('show')
  }
}

const Footer = ({ location: { pathname } }: Props) => {
  const isLocation = pathname === '/'

  return (
    <FooterContainer isLanding={isLocation}>
      <FooterWrapper isLanding={isLocation}>
        <Button variant="secondary" onClick={() => openHelpDialog()}>Задать вопрос в поддержку</Button>
        <LinkContainer>
          {Object.entries(links)
            .map(([key, { title, link }]) => (
              <StyledLink key={key} href={link}>{title}</StyledLink>))
          }
        </LinkContainer>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default withRouter(Footer)
