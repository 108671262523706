const AUDIENCE = [
  {
    text: 'Владельцы ресурсов для путешественников',
  },
  {
    text: 'Веб-маcтера (контекст, тизерные сети)',
  },
  {
    text: 'Тревел–блогеры',
  },
  {
    text: 'Админы тревел-пабликов',
  },
]

export {
  AUDIENCE,
}
