// @flow
import React, { PureComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import UIHeader from '@kupibilet/ui/components/Header'
import Icon from '@kupibilet/ui/components/Icon'
import { withMedia } from '@kupibilet/ui/utils/media-queries'
import Link from '@kupibilet/ui/components/Link'
import {
  StyledLink,
  StyledDropdown,
  FakeLink,
  HeaderLogo,
  RouterDropdownLink,
  DropdownLink,
} from './styled'

type Props = {
  email: string,
  setAppState: ({}) => void,
  logoutMutation?: {},
  logoutLink?: string,
  links?: Array<Object>,
  unauthorizedLinks?: Array<Object>,
  logoLink?: string,
  isHandheld: boolean,
  enableHelpLink?: boolean,
  renderUnauthorizedLinks?: ({}) => React.Node,
}
type State = {
  menuIsOpen: boolean,
}

class Header extends PureComponent<Props, State> {
  static defaultProps = {
    unauthorizedLinks: [],
    links: [],
    logoLink: '/',
    logoutMutation: null,
    logoutLink: null,
    enableHelpLink: false,
    renderUnauthorizedLinks: null,
  }

  state = {
    menuIsOpen: false,
  }

  onToggle = () => {
    this.setState(({ menuIsOpen }) => ({ menuIsOpen: !menuIsOpen }))
  }

  renderLogoutLink = () => {
    const { setAppState, logoutMutation, logoutLink } = this.props
    if (logoutLink) {
      return (
        <Link href={logoutLink}>
          Выйти
        </Link>
      )
    }

    return (
      <Mutation
        mutation={logoutMutation}
        onCompleted={() => setAppState({ email: null })}
      >
        {(logout) => (
          <FakeLink onClick={logout}>Выйти</FakeLink>
        )}
      </Mutation>
    )
  }

  renderDropdownContent = () => {
    const { isHandheld, links, enableHelpLink } = this.props
    return isHandheld ? (
      <>
        {links.map(({ title, link }) => (
          <RouterDropdownLink key={title} as={RouterLink} to={link}>{title}</RouterDropdownLink>
        ))}
        {enableHelpLink ? (
          <DropdownLink href="https://help-with.kupibilet.ru/" target="_blank">Помощь</DropdownLink>
        ) : null}
        {this.renderLogoutLink()}
      </>
    ) : this.renderLogoutLink()
  }

  renderDropdownButton = () => {
    const { email, isHandheld } = this.props
    return isHandheld ? (
      <Icon
        name="burger"
        size="medium"
        fill="primaryDarker"
      />
    ) : (
      <FakeLink>{email}</FakeLink>
    )
  }

  renderUnauthorizedLinks = () => {
    const {
      unauthorizedLinks,
      renderUnauthorizedLinks,
    } = this.props

    return renderUnauthorizedLinks || (
      <div>
        {unauthorizedLinks.map(({ title, link }) => (
          <StyledLink key={title} as={RouterLink} to={link}>{title}</StyledLink>
        ))}
      </div>
    )
  }

  renderRightSide = () => {
    const { email } = this.props
    const { menuIsOpen } = this.state

    return email ? (
      <StyledDropdown
        overlay={this.renderDropdownContent()}
        onToggle={this.onToggle}
        isOpen={menuIsOpen}
      >
        {this.renderDropdownButton()}
      </StyledDropdown>
    ) : this.renderUnauthorizedLinks()
  }

  renderLeftSide = () => {
    const { email, links, isHandheld, enableHelpLink } = this.props
    return email && !isHandheld ? (
      <div>
        {links.map(({ title, link }) => (
          <StyledLink key={title} as={RouterLink} to={link}>{title}</StyledLink>
        ))}
        {enableHelpLink ? (
          <Link href="https://help-with.kupibilet.ru/" target="_blank">Помощь</Link>
        ) : null}
      </div>
    ) : <div />
  }

  render() {
    const { logoLink } = this.props
    return (
      <UIHeader
        renderLogo={() => <HeaderLogo as={RouterLink} to={logoLink} />}
        renderLeftSide={this.renderLeftSide}
        renderRightSide={this.renderRightSide}
      />
    )
  }
}

export default withMedia(Header)
