import percent from '../assets/persent.svg'
import conversion from '../assets/conversion.svg'
import payment from '../assets/payment-methods.svg'

const SLOGANS = [
  {
    name: 'percent',
    text: 'От 2% с каждого оплаченного авиабилета',
    src: percent,
  },
  {
    name: 'conversion',
    text: 'Высокая конверсия: 5% CR',
    src: conversion,
  },
  {
    name: 'payment',
    text: 'Бесплатный и удобный вывод средств',
    src: payment,
  },
]

export {
  SLOGANS,
}
