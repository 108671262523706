import gql from 'graphql-tag'

export const REGISTER_MUTATION = gql`
  mutation RegisterPartner(
    $name: String!,
    $email: EmailAddress!,
    $password: String!,
    $walletType: WALLET_TYPES!,
    $walletNumber: String!,
    $comment: String!
  ) {
    partner {
      create(input: {
        name: $name,
        email: $email,
        password: $password,
        walletType: $walletType,
        walletNumber: $walletNumber,
        comment: $comment
      }) {
        email
      }
    }
  }
`
