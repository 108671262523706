// @flow
import React, { PureComponent } from 'react'
import Button from '@kupibilet/ui/components/Button'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  title: string,
  to: string,
  size: string,
  clickHandler?: () => void,
}

class RouterButton extends PureComponent<Props> {
  static defaultProps = {
    clickHandler: null,
  }

  render() {
    const { title, to, size = 'large', clickHandler } = this.props
    return (
      <RouterLink
        to={to}
        onClick={clickHandler}
      >
        <Button
          size={size}
        >
          {title}
        </Button>
      </RouterLink>
    )
  }
}

export default RouterButton
