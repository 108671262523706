import React from 'react'
import styled from 'styled-components'

import fetch from 'isomorphic-fetch'
import _ from 'lodash'

import Autocomplete from '@kupibilet/ui/components/Autocomplete'
import AirportInput from '@kupibilet/ui/components/AirportInput'
import AirportSuggest from '@kupibilet/ui/components/AirportSuggest'

class AirportSelect extends React.PureComponent {
  static propTypes = Autocomplete.propTypes

  static defaultProps = {
    ...Autocomplete.defaultProps,
    filterSuggestions: () => true,
  }

  state = {
    value: '',
    suggest: null,
    suggestions: [],
  }

  /* eslint-disable react/sort-comp */
  onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    const { onSelected } = this.props
    this.setState({
      suggest: suggestion,
      value: suggestionValue,
    })

    if (onSelected) {
      onSelected(suggestion)
    }
  }

  onChange = (event, { newValue, suggestion, method }) => {
    const { suggest } = this.state
    const { onSelected } = this.props

    if (method !== 'blur' || !suggest) {
      this.setState({
        suggest: newValue && suggestion || null,
        value: newValue || '',
      })

      if (!newValue && onSelected) {
        onSelected({ suggest: null, value: '' })
      }
    }
  }

  fetchSuggestions = _.throttle(({ value }) => {
    fetch(`https://suggest.kupibilet.ru/suggest.json?term=${value}`)
      .then((data) => data.json())
      .then(({ data }) => {
        const { filterSuggestions } = this.props

        return data
          .map((suggest) => {
            const IATACode = suggest.code
            const isCity = !suggest.city_code
            const city = (suggest.city_name || suggest.name || {}).ru
            const country = suggest.country_name && suggest.country_name.ru

            return {
              value: (suggest.name || {}).ru,
              isCity,
              area: (isCity ? country : city) || '',
              city,
              country,
              IATACode,
            }
          })
          .filter(filterSuggestions)
      })
      .then((suggestions) => {
        // eslint-disable-next-line react/destructuring-assignment
        if (value === this.state.value) {
          this.setState({ suggestions })
        }
      })
      .catch(console.error)
  }, 500)

  clearSuggestions = () => {
    this.setState({
      suggestions: [],
    })
  }

  shouldRenderSuggestions = (value) => {
    const { suggest } = this.state

    return !suggest && value.trim().length > 1
  }

  render() {
    const { value, suggest, suggestions } = this.state
    const { inputProps } = this.props

    return (
      <Autocomplete
        {...this.props}
        inputProps={{
          ...inputProps,
          value,
          onChange: this.onChange,
          type: 'text',
          ...suggest,
        }}
        highlightFirstSuggestion
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.fetchSuggestions}
        onSuggestionsClearRequested={this.clearSuggestions}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestion={(suggestion) => (
          <AirportSuggest {...suggestion} />
        )}
        renderInputComponent={(props) => (
          <AirportInput {...props} />
        )}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      />
    )
  }
}

export default styled(AirportSelect)`
  flex-grow: 1;
  flex-shrink: 0;
`
