import React, { PureComponent } from 'react'
import withRedirectIfAuthorized from 'partner/HOC/withRedirectIfAuthorized'
import Hero from './components/Hero'
import Benefits from './components/Benefits'
import Register from './components/Register'
import TargetAudience from './components/TargetAudience'
import ProgramTools from './components/ProgramTools'
import Comments from './components/Comments'
import Airline from './assets/airline.svg'
import { StyledSeparatorImage } from './styled'

class Landing extends PureComponent {
  render() {
    return (
      <>
        <Hero />
        <Benefits />
        <ProgramTools />
        <TargetAudience />
        <Comments />
        <Register />
        <StyledSeparatorImage image={Airline} />
      </>
    )
  }
}

export default withRedirectIfAuthorized(Landing)
