import gql from 'graphql-tag'

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($partnerId: ID!) {
    payment {
      create(partnerId: $partnerId) {
        id
      }
    }
  }
`
