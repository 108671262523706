import styled from 'styled-components'
import { Select } from '@kupibilet/ui/components/Select'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledSelect = styled(Select)`
  height: 30px;
`
