// @flow
import React, { Component } from 'react'
import moment from '@kupibilet/moment'
import type { PartnerTypeFilterState } from 'shared/types'
import Filters from './Filters'
import Table from './Table'
import { SHOW_FOR_OPTIONS } from './consts'
import type { STATS_TYPE } from './types'

type Props = {
  partnerId?: number,
  statsType: STATS_TYPE,
  hasGroupingByTag?: boolean,
  hasGroupingByAgentCode?: boolean,
  hasPartnerTypeFilter?: boolean,
  hasAgentCodesFilter?: boolean,
}

type State = {
  groupBy: string,
  showFor: string,
  uniqueUsers: boolean,
  startDate: Date,
  endDate: Date,
  partnerTypeFilter: PartnerTypeFilterState,
  agentCodesFilter: string[],
}

class StatsTable extends Component<Props, State> {
  static defaultProps = {
    partnerId: null,
    hasGroupingByTag: false,
    hasGroupingByAgentCode: false,
    hasPartnerTypeFilter: false,
    hasAgentCodesFilter: false,
  }

  state = {
    groupBy: 'date',
    showFor: 'week',
    uniqueUsers: false,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    partnerTypeFilter: {
      all: true,
      partner: true,
      ad_network: true,
    },
    agentCodesFilter: [],
  }

  updateState = (updatedFields) => {
    this.setState(updatedFields)
  }

  render() {
    const {
      partnerId,
      statsType,
      hasGroupingByTag,
      hasGroupingByAgentCode,
      hasPartnerTypeFilter,
      hasAgentCodesFilter,
    } = this.props
    const {
      groupBy,
      showFor,
      uniqueUsers,
      startDate,
      endDate,
      partnerTypeFilter,
      agentCodesFilter,
    } = this.state

    let queryStartDate = startDate
    let queryEndDate = endDate

    if (showFor === SHOW_FOR_OPTIONS.WEEK) {
      queryEndDate = moment()
      queryStartDate = moment().subtract(1, 'weeks')
    } else if (showFor === SHOW_FOR_OPTIONS.MONTH) {
      queryEndDate = moment()
      queryStartDate = moment().subtract(1, 'months')
    }

    return (
      <>
        <Filters
          partnerTypeFilter={partnerTypeFilter}
          hasPartnerTypeFilter={hasPartnerTypeFilter}
          hasGroupingByTag={hasGroupingByTag}
          hasGroupingByAgentCode={hasGroupingByAgentCode}
          hasAgentCodesFilter={hasAgentCodesFilter}
          groupBy={groupBy}
          showFor={showFor}
          uniqueUsers={uniqueUsers}
          startDate={startDate}
          endDate={endDate}
          updateState={this.updateState}
        />

        <Table
          partnerTypeFilter={partnerTypeFilter}
          agentCodesFilter={agentCodesFilter}
          hasAgentCodesFilter={hasAgentCodesFilter}
          statsType={statsType}
          partnerId={partnerId}
          groupBy={groupBy}
          uniqueUsers={uniqueUsers}
          startDate={queryStartDate}
          endDate={queryEndDate}
        />
      </>
    )
  }
}


export default StatsTable
