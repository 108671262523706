import styled from 'styled-components'
import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'
import AdSlogan from '@kupibilet/ui/components/AdSlogan'
import mq from '@kupibilet/ui/utils/media-queries'

const StyledSingleFluidSection = styled(SingleFluidSection)`
  padding: 60px 42px 102px;
  text-align: left;
  ${mq.handheld`
    padding: 24px 20px 90px;
  `}
  ${mq.mobile`
    padding: 0;
   `}
`

const SlogansWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
  margin-top: 45px;
  ${mq.mobile`
    flex-wrap: wrap;
    margin: 0;
  `}
`

const StyledAdSlogan = styled(AdSlogan)`
  & > p{
    width: 140px;
    flex-basis: 140px;
  }
`

export {
  StyledSingleFluidSection,
  SlogansWrapper,
  StyledAdSlogan,
}
