import React from 'react'
import AppContext from 'partner/contexts/AppContext'
import { Redirect } from 'react-router-dom'

function withRedirectIfAuthorized(Component) {
  return function WithRedirectIfAuthorized(props) {
    return (
      <AppContext.Consumer>
        {(appContext) => {
          if (appContext.isLoggedIn()) {
            return <Redirect to="/partner/profile" />
          }

          return <Component {...props} />
        }}
      </AppContext.Consumer>
    )
  }
}

export default withRedirectIfAuthorized
