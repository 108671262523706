// @flow
import React from 'react'
import styled from 'styled-components'
import type { FieldAttributes } from 'formik'
import { Input } from '@kupibilet/ui/components/Input'
import FormItem from '@kupibilet/ui/components/FormItem'
import mq from '@kupibilet/ui/utils/media-queries'

export const InputContainer = styled.div`
  display: inline-block;

  & + & {
    margin-left: 18px;
    ${mq.mobile`
      margin-left: 0;
    `}
  }

  width: ${({ isWide, isTextarea }) => {
    if (isWide) return '100%'

    return isTextarea ? '498px' : '240px'
  }};

  ${mq.tablet`
    flex-grow: 1;
    // limiting max-width to half of screen minus half of inputs margin
    max-width: calc(50% - 9px);
  `}

  ${mq.mobile`
    width: 100%;
    margin-bottom: 12px;
  `}
`

const FormInput = ({
  label,
  form: {
    touched,
    errors,
  },
  field,
  isTextarea,
  isWide,
  type,
  ...props
}: FieldAttributes) => (
  <InputContainer isTextarea={isTextarea} isWide={isWide}>
    <FormItem label={label}>
      <Input
        {...field}
        {...props}
        // Force decimal point instead of comma in number input
        lang={type === 'number' ? 'en' : null}
        type={type}
        error={touched[field.name] && errors[field.name]}
        isTextarea={isTextarea}
      />
    </FormItem>
  </InputContainer>
)

export default FormInput
