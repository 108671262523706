// @flow
import * as React from 'react'
import List from 'react-virtualized/dist/commonjs/List'
import styled from 'styled-components'
import Modal from '@kupibilet/ui/components/Modal'
import { Query } from 'react-apollo'
import moment from 'moment'
import _ from 'lodash'
import Price from '@kupibilet/ui/components/Typography/Price'
import Link from '@kupibilet/ui/components/Link'
import type { ApolloQuery } from 'shared/types/apollo'
import withQueryLoading from 'shared/HOC/withQueryLoading'
import {
  Table,
  TableWrapper,
  BlackPrice,
} from 'shared/components'
import formatCurrency from 'shared/utils/formatCurrency'
import getTokenLink from 'shared/utils/getTokenLink'
import { TableRow, TableCell, Legend, LegendCell } from 'shared/components/VirtualizedTables'
import { GET_DETAILED_STATS_QUERY } from './queries'

const StyledLink = styled(Link)`
  display: inline;
`

function renderTableRow({
  itinerary,
  ticketsTotal,
  crossTotal,
  passengersInfo,
  datetime,
  revenue,
  datesInfo,
  token,
}, {
  style,
  key,
  groupBy,
  agentCode,
  showToken,
  showCrossales,
}) {
  const tokenLink = getTokenLink({ token, agentCode })

  return (
    <TableRow style={style} key={key}>
      <TableCell>
        {groupBy === 'marker'
          ? moment.utc(datetime).format('DD.MM HH:mm')
          : moment.utc(datetime).format('HH:mm')
        }
      </TableCell>
      <TableCell title={itinerary}>{itinerary}</TableCell>
      <TableCell title={formatCurrency(ticketsTotal)}>
        <BlackPrice>{formatCurrency(ticketsTotal)}</BlackPrice>
      </TableCell>
      {showCrossales && (
        <TableCell title={formatCurrency(crossTotal)}>
          <BlackPrice>{formatCurrency(crossTotal)}</BlackPrice>
        </TableCell>
      )}
      <TableCell title={formatCurrency(revenue)}>
        <Price>{formatCurrency(revenue)}</Price>
      </TableCell>
      <TableCell title={datesInfo}>{datesInfo}</TableCell>
      <TableCell>{passengersInfo}</TableCell>
      {showToken && (
        <TableCell flexGrow={1}>
          <StyledLink
            target="_blank"
            href={tokenLink}
          >
            {token}
          </StyledLink>
        </TableCell>
      )}
    </TableRow>
  )
}

type Props = {
  agentCode: string,
  modalIsOpen: boolean,
  closeModal: () => void,
  groupBy: string,
  groupByValue?: string,
  startDate: string,
  endDate: string,
  statsType: string,
  showCrossales: boolean,
}

class DetailedStats extends React.Component<Props> {
  static defaultProps = {
    groupByValue: '',
  }

  render() {
    const {
      modalIsOpen,
      closeModal,
      groupBy,
      groupByValue,
      agentCode,
      startDate,
      endDate,
      statsType,
      showCrossales,
    } = this.props

    const variables = {
      agentCode,
      groupBy,
      groupByValue,
      startDate,
      endDate,
    }

    const modalHeading = groupBy === 'marker'
      ? `Отчёт о бронированиях по\u00A0маркеру\u00A0${groupByValue}`
      : `Отчёт о бронированиях за\u00A0${groupByValue}`

    const showToken = statsType === 'partnerStatsForAdmin'

    return (
      <Modal
        heading={modalHeading}
        isOpen={modalIsOpen}
        onClose={closeModal}
        scrollFix={false}
        size="wide"
      >
        <Query query={GET_DETAILED_STATS_QUERY} variables={variables}>
          {withQueryLoading((apolloQuery: ApolloQuery) => {
            const stats = _.get(apolloQuery, 'data.partner.getDetailedStats', [])

            if (stats.length === 0) {
              return (
                <div>
                  Нет данных
                  {groupBy === 'marker' ? ' по выбранному маркеру' : ' за выбранную дату'}
                </div>
              )
            }

            return (
              <TableWrapper>
                <Table>
                  <Legend>
                    <LegendCell>Время</LegendCell>
                    <LegendCell>Города</LegendCell>
                    <LegendCell>Цена билетов</LegendCell>
                    {showCrossales && <LegendCell>Цена доп.&nbsp;услуг</LegendCell>}
                    <LegendCell>Доход</LegendCell>
                    <LegendCell>Даты</LegendCell>
                    <LegendCell>Пассажиры</LegendCell>
                    {showToken && <LegendCell flexGrow={1}>Токен</LegendCell>}
                  </Legend>

                  <List
                    rowCount={stats.length}
                    height={500}
                    rowHeight={50}
                    rowRenderer={({ index, key, style }) => renderTableRow(
                      stats[index], { style, key, groupBy, agentCode, showToken, showCrossales },
                    )}
                    width={790}
                  />
                </Table>
              </TableWrapper>
            )
          })}
        </Query>
      </Modal>
    )
  }
}


export default DetailedStats
