import React from 'react'
import { Wrapper, Title, Subtitle } from './styled'
import RegisterButton from '../blocks/RegisterButton'

const Hero = () => (
  <Wrapper>
    <Title>
      Зарабатывайте на продаже авиабилетов
    </Title>
    <Subtitle>
      Получите высокий доход с ваших тревел-ресурсов
    </Subtitle>
    <RegisterButton />
  </Wrapper>
)

export default Hero
