// @flow
import * as React from 'react'
import Text from '@kupibilet/ui/components/Typography/Text'
import formatCurrency from 'shared/utils/formatCurrency'
import type { Agent, Partner } from 'shared/types'
import locales from 'shared/locales'
import { HalfColumn } from 'shared/components'
import { Wrapper, Row, Key } from './styled'
import Attention from './parts/Attention'

type Props = {
  ...Agent,
  ...Partner,
}

class PartnerInfo extends React.PureComponent<Props> {
  renderRow = (key: string, addSymbol: string = '') => {
    const { props } = this
    return (
      <Row>
        <Key>{locales[key] || key}</Key>
        {props[key] ? (
          <Text>{props[key]}{addSymbol && `\u00a0${addSymbol}`}</Text>
        ) : ('—')}
      </Row>
    )
  }

  render() {
    const { workMode, crossalesEnable } = this.props
    const isCPC = workMode === 'cpc'

    return (
      <Wrapper>
        <HalfColumn>
          {this.renderRow('name')}
          {this.renderRow('agentCode')}
          {this.renderRow('walletType')}
          {this.renderRow('walletNumber')}
        </HalfColumn>
        <HalfColumn>
          {this.renderRow('workMode')}
          {this.renderRow('referralPercent', '%')}
          {isCPC ? (
            formatCurrency(this.renderRow('createdFeeKop'))
          ) : (
            <>
              {this.renderRow('profitFee', '%')}
              {crossalesEnable && this.renderRow('crossalesFee', '%')}
            </>
          )}
        </HalfColumn>
        <Attention>
          Для изменения условий сотрудничества или реквизитов свяжитесь с поддержкой
        </Attention>
      </Wrapper>
    )
  }
}

export default PartnerInfo
