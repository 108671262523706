import styled from 'styled-components'
import { Container } from 'shared/components'
import confusedRabbit from './assets/confused-rabbit.svg'

export const Image = styled.div`
  background: url(${confusedRabbit}) no-repeat top center;
  height: 221px;
`

export const FlexContainer = styled(Container)`
  padding-top: 42px;
  display: flex;
`

export const LeftSide = styled(Container)`
  width: 50%;
`

export const RightSide = styled(Container)`
  margin-top: 54px;
  width: 50%;
`
