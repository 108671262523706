import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import H5 from '@kupibilet/ui/components/Typography/H5'
import { H1, FlexBoxContainer } from 'shared/components'
import palmsDesctop from './assets/palms-desctop.svg'
import palmsMobile from './assets/palms-mobile.svg'

const Wrapper = styled(FlexBoxContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 18px 87px;
  background-image: url(${palmsDesctop});
  background-color: ${({ theme }) => theme.color.miscLightest};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  ${mq.tablet`
    padding: 45px 18px;
  `}
  ${mq.mobile`
    background-image: url(${palmsMobile});
    background-position: center bottom;
    padding: 45px 18px 90px;
  `}
`

const Title = styled(H1)`
  text-align: center;
  max-width: 540px;
  ${mq.mobile`
    margin-bottom: 12px;
  `}
`

const Subtitle = styled(H5)`
  margin-bottom: 36px;
  font-weight: 400;
  text-align: center;
  max-width: 354px;
  ${mq.mobile`
    margin-bottom: 24px;
  `}
`

export {
  Wrapper,
  Title,
  Subtitle,
}
