// @flow
import * as React from 'react'
import { Input } from '@kupibilet/ui/components/Input'
import {
  H2,
  Table,
  Legend,
  LegendCell,
  TableRow,
  TableCell,
  Status,
  H3,
  FlexBoxContainer,
} from 'shared/components'
import type { PartnerStatus } from 'shared/types'
import { InputContainer } from 'shared/components/FormInput'

import {
  TableWrapper,
  StyledLink,
  InviteWrapper,
  Description,
  StyledFormItem,
} from './styled'

type Referral = {
  name: string,
  status: PartnerStatus,
}

type Props = {
  referrals: [Referral],
  referralPercent: number,
  agentCode: string,
}

type Stats = {
  showFullList: false,
  sliceTo: number,
}

const renderReferralLink = (agentCode) => {
  return (
    <InputContainer isWide>
      <StyledFormItem label="Поделитесь ссылкой на регистрацию:">
        <Input
          value={`https://with.kupibilet.ru/partner/register?ref=${agentCode}`}
          readonly
          onClick={(e) => e.target.select()}
        />
      </StyledFormItem>
    </InputContainer>
  )
}

class Referrals extends React.Component<Props, Stats> {
  state = {
    showFullList: false,
    sliceTo: 2,
  }

  toggleShowFullList = () => {
    this.setState(({ showFullList }) => ({
      showFullList: !showFullList,
    }))
  }

  renderReferral = (referral: Referral) => (
    <TableRow key={referral.name}>
      <TableCell>
        {referral.name}
      </TableCell>
      <TableCell>
        <Status status={referral.status} />
      </TableCell>
    </TableRow>

  )

  renderReferralTable = () => {
    const { referrals } = this.props
    const { showFullList, sliceTo } = this.state
    if (!referrals.length) return 'У вас пока нет ни одного реферала'
    return (
      <TableWrapper>
        <Table>
          <Legend>
            <LegendCell>
              Имя
            </LegendCell>
            <LegendCell>
              Статус
            </LegendCell>
          </Legend>
          {showFullList ? (
            referrals.map(this.renderReferral)
          ) : (
            referrals.slice(0, sliceTo).map(this.renderReferral)
          )}
        </Table>
        {referrals.length > sliceTo && !showFullList &&
          <StyledLink as="span" onClick={this.toggleShowFullList}>
            Показать всех...
          </StyledLink>
        }
      </TableWrapper>
    )
  }

  render() {
    const { referralPercent, agentCode } = this.props
    return (
      <>
        <H2>Рефералы</H2>
        <FlexBoxContainer>
          {this.renderReferralTable()}
          <InviteWrapper>
            <H3>Пригласите друга</H3>
            <Description as="p">
              Получайте {referralPercent}% от прибыли каждого приведённого партнёра.
              {renderReferralLink(agentCode)}
            </Description>
          </InviteWrapper>
        </FlexBoxContainer>
      </>
    )
  }
}

export default Referrals
