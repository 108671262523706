export default {
  name: 'Имя',
  agentCode: 'Код агента',
  walletNumber: 'Номер кошелька',
  walletType: 'Тип кошелька',
  workMode: 'Тип выплат',
  referralPercent: 'Процент с рефералов',
  createdFeeKop: 'Стоимость клика',
  profitFee: 'Процент с билетов',
  crossalesFee: 'Процент с доп. услуг',
  status: 'Статус',
  comment: 'Комментарий',
  createdAt: 'Дата регистрации',
}
