import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import WithLabel from 'shared/pages/Constructors/components/WithLabel'
import DestinationsGroup from 'shared/pages/Constructors/components/DestinationsGroup'
import Tip from 'shared/pages/Constructors/components/Tip'
import { Checkbox } from '@kupibilet/ui/components/Checkbox'
import Marker from 'shared/pages/Constructors/constructors/Marker'
import AirportSelect from 'shared/pages/Constructors/components/AirportSelect'
import { Container, StyledInput, StyledLinkValue } from './styled'

class LPCConstructor extends React.PureComponent {
  state = {
    showTrackingRedirect: false,
    trackingRedirectURL: '',
    originalUrlParam: '',
    departure: null,
    arrival: null,
    height: '445px',
    width: '100%',
    selector: '',
  }

  componentDidMount() {
    const script = window.document.createElement('script')
    script.async = true
    script.src = 'https://widgets.kupibilet.ru/lpc/v1.3.23/'
    window.document.head.appendChild(script)

    script.onload = this.reinjectWidget
  }

  componentDidUpdate() {
    this.reinjectWidget()
  }

  _getWidgetOpts = () => {
    const { selectedMarker, agentCode } = this.props
    const {
      selector,
      departure,
      arrival,
      width,
      height,
      prefix,
      suffix,
      showTrackingRedirect,
      trackingRedirectURL,
      originalUrlParam,
    } = this.state

    if (!departure || !arrival) {
      return
    }

    const q = { agent: agentCode }
    if (selectedMarker) {
      q.marker = selectedMarker
    }
    const trackingRedirect = showTrackingRedirect && trackingRedirectURL && originalUrlParam && {
      url: trackingRedirectURL,
      originalUrlParam,
    }

    return _.pickBy({
      q,
      node: selector,
      width,
      height,
      departure,
      arrival,
      trackingRedirect,
      title_prefix: prefix,
      title_suffix: suffix,
    }, Boolean)
  }

  /* eslint-disable react/sort-comp */
  reinjectWidget = _.debounce(() => {
    const opts = this._getWidgetOpts()
    const node = document.getElementById('playground')
    Array.from(node.children).forEach((child) => child.remove())

    if (!opts) {
      return
    }
    try {
      window.Kupibilet.lpcWidget({
        ...opts,
        node,
      })
    } catch (e) { console.warn(e) }
  }, 1000)

  getRefLink = () => {
    const opts = this._getWidgetOpts()
    if (!opts) {
      return 'Заполните вылет и прилёт'
    }

    return `
  <script src="https://widgets.kupibilet.ru/lpc/1" type="text/javascript"></script>
  <script>window.Kupibilet.lpcWidget(${JSON.stringify(opts)})</script>
    `.replace(/^\n|\n$/, '')
  }

  onDepartureSelected = ({ IATACode }) => this.setState({ departure: IATACode })

  onArrivalSelected = ({ IATACode }) => this.setState({ arrival: IATACode })

  onTrackingRedirectToggle = () => {
    const { showTrackingRedirect } = this.state
    this.setState({ showTrackingRedirect: !showTrackingRedirect })
  }

  onWidthChange = ({ target }) => this.setState({ width: target.value })

  onPrefixChange = ({ target }) => this.setState({ prefix: target.value })

  onSuffixChange = ({ target }) => this.setState({ suffix: target.value })

  onSelectorChange = ({ target }) => this.setState({ selector: target.value.trim() })

  onRedirectURLChange = ({ target }) => this.setState({ trackingRedirectURL: target.value })

  onOriginalUrlParamChange = ({ target }) => this.setState({ originalUrlParam: target.value })

  render() {
    const {
      showTrackingRedirect,
      trackingRedirectURL,
      originalUrlParam,
      width,
      height,
      prefix,
      suffix,
      selector,
    } = this.state

    return (
      <Container>

        <Marker />

        <WithLabel label="Префикс заголовка">
          <StyledInput value={prefix} onChange={this.onPrefixChange} placeholder="Динамика цен" />
        </WithLabel>

        <WithLabel label="Постфикс заголовка">
          <StyledInput value={suffix} onChange={this.onSuffixChange} placeholder="на сегодня" />
        </WithLabel>

        <WithLabel label="Направление перелёта">
          <DestinationsGroup>
            <AirportSelect onSelected={this.onDepartureSelected} inputProps={{ placeholder: 'Вылет из' }} />
            <AirportSelect onSelected={this.onArrivalSelected} inputProps={{ placeholder: 'Прилёт в' }} />
          </DestinationsGroup>
        </WithLabel>

        <WithLabel label="Ширина">
          <Tip>
            Виджет адаптивен, и безопасно сжимается по горизонтали,
            без потери функциональности.<br />
            При использовании значений <code>100%</code> или <code>auto</code> виджет растягивается
            на всю доступную ширину и сужается на моб. устройствах.
          </Tip>
          <StyledInput value={width} onChange={this.onWidthChange} placeholder="1234px или 100% или auto" />
        </WithLabel>

        <WithLabel label="Высота">
          <Tip>
            Виджет сохраняет фиксированную высоту на всех устройствах
          </Tip>
          <StyledInput value={height} disabled />
        </WithLabel>

        <WithLabel>
          <Checkbox checked={showTrackingRedirect} onChange={this.onTrackingRedirectToggle}>
            Отслеживание переходов с помощью редиректа
          </Checkbox>
          { showTrackingRedirect &&
            <Fragment>
              <Tip>
                Вы можете использовать свою страницу
                для отслеживания переходов на своей стороне.<br />
                Например у вас есть страница <code>https://abc.com/count</code><br />
                Все переходы с форма могут быть направлены на эту страницу,
                чтобы посчитать их количество, но ваша
                страница <b>обязательно</b> должна сделать переадресацию на исходный URL,
                на который изначально указывала форма.<br />
                Мы передадим его вашей странице в параметре, например <code>redir</code><br />
                Это значит что назаж кнопку найти пользователь
                перейдёт на страницу <code>https://abc.com/count?redir=https://kupibilet.ru/search/</code>
                и будет моментально переадресован на <code>https://kupibilet.ru/search/</code>
              </Tip>

              <WithLabel label="Полный URL страницы отслеживания">
                <StyledInput
                  value={trackingRedirectURL}
                  onChange={this.onRedirectURLChange}
                  placeholder="https://www.google.com/search"
                />
              </WithLabel>

              <WithLabel label="Название параметра с исходным URL">
                <StyledInput
                  value={originalUrlParam}
                  onChange={this.onOriginalUrlParamChange}
                  placeholder="q"
                />
              </WithLabel>
              <Tip>
                  Проверьте работу редиректа нажав кнопку &quot;Найти&quot; в превью виджета ниже
              </Tip>
            </Fragment>
          }
        </WithLabel>

        <WithLabel label="Селектор контейнера в который вставится виджет" htmlFor="refLink">
          <StyledInput value={selector} onChange={this.onSelectorChange} placeholder="#kupibilet-widget" />
        </WithLabel>

        <WithLabel label="Код для вставки" htmlFor="refLink">
          <Tip>
            {
              !selector
                ? 'Вставьте этот код на сайт в то место, где должен появиться виджет'
                : <span>Вставьте этот код перед закрывающимся тегом <code>{ '</body>' }</code></span>
            }
          </Tip>
          <StyledLinkValue isTextarea value={this.getRefLink()} id="refLink" />
        </WithLabel>

        <WithLabel label="Демо" htmlFor="refLink">
          <div id="playground" />
        </WithLabel>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedMarker: state.markers.selectedMarker,
})

const withConnect = connect(mapStateToProps)

export default withConnect(LPCConstructor)
