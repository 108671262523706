import React from 'react'
import { Helmet } from 'react-helmet'

const PageTitle = ({ children }) => (
  <Helmet>
    <title>{children}</title>
  </Helmet>
)

export default PageTitle
