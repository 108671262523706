import gql from 'graphql-tag'

export const GET_DETAILED_STATS_QUERY = gql`
  query GetDayStats(
    $agentCode: String!,
    $groupBy: STATS_GROUPING_OPTIONS!
    $groupByValue: String
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    partner {
      getDetailedStats(input: {
        agentCode: $agentCode
        groupBy: $groupBy
        groupByValue: $groupByValue
        startDate: $startDate
        endDate: $endDate
      }) {
        itinerary
        ticketsTotal
        crossTotal
        passengersInfo
        datetime
        revenue
        datesInfo
        token
      }
    }
  }
`
