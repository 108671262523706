import styled from 'styled-components'
import { Input } from '@kupibilet/ui/components/Input'
import LinkValue from 'shared/pages/Constructors/components/LinkValue'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledInput = styled(Input)`
  height: 30px;
`
export const StyledLinkValue = styled(LinkValue)`
  min-height: 120px;
`
