import React from 'react'
import TextLarge from '@kupibilet/ui/components/Typography/TextLarge'
import { H2 } from 'shared/components'
import {
  StyledSingleFluidSection,
  SlogansWrapper,
  StyledAdSlogan,
} from './styled'
import { SLOGANS } from './parts/texts'

const Benefits = () => (
  <StyledSingleFluidSection>
    <H2>
        Как работает партнёрская программа?
    </H2>
    <TextLarge as="p">
        Вы регистрируетесь в программе и получаете доступ к личному кабинету партнёра.
        Размещаете на своём сайте промо-инструменты и привлекаете пользователей.
        Они покупают билеты, а вы получаете вознаграждение.
    </TextLarge>
    <SlogansWrapper>
      {Object.entries(SLOGANS).map(([key, value]) => (
        <StyledAdSlogan key={key} {...value} />
      ))}
    </SlogansWrapper>
  </StyledSingleFluidSection>
)

export default Benefits
