import styled from 'styled-components'
import mq from '@kupibilet/ui/utils/media-queries'
import { SingleFluidSection } from '@kupibilet/ui/components/FluidLayout'

const StyledSingleFluidSection = styled(SingleFluidSection)`
  padding: 24px 0 90px;
  border-top: 1px solid rgb(221, 227, 235);
  text-align: center;
  ${mq.handheld`
    padding: 42px 0;
  `}
  ${mq.mobile`
    padding: 18px 0 36px;
  `}
`

export {
  StyledSingleFluidSection,
}
