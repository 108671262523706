import styled from 'styled-components'
import FormItem from '@kupibilet/ui/components/FormItem'
import mq from '@kupibilet/ui/utils/media-queries'

const CheckboxFormItem = styled(FormItem)`
  flex-basis: 210px;
  ${mq.mobile`
    flex-basis: 50%;
  `}
`

export default CheckboxFormItem
