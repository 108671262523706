import React, { PureComponent } from 'react'
import { StickyTableCell, TableCell, TableRow as UITableRow } from 'shared/components/Tables'
import formatCurrency from 'shared/utils/formatCurrency'
import styled from 'styled-components'
import Text from '@kupibilet/ui/components/Typography/Text'

const Currency = styled(Text)`
  color: ${({ theme }) => theme.color.secondaryDarker};
  font-weight: 700;
`

class TableRow extends PureComponent {
  handleClick = () => {
    const { workMode, openModal, row: { groupBy } } = this.props

    if (workMode === 'cpa') {
      openModal(groupBy)
    }
  }

  render() {
    const {
      row: {
        groupBy,
        visits,
        searches,
        created,
        reserved,
        issued,
        revenue,
        referralsRevenue,
        profit,
        purchasesConversion = 0,
        orderTotal,
      },
      showRefferralsRevenue,
      workMode,
      statsType,
    } = this.props

    const formattedPurchasesConversion = (purchasesConversion * 100).toFixed(2)

    return (
      <UITableRow
        key={groupBy}
        onClick={this.handleClick}
        shouldHover={workMode === 'cpa'}
      >
        <StickyTableCell>{groupBy || '-'}</StickyTableCell>
        <TableCell>{visits}</TableCell>
        <TableCell>{searches}</TableCell>
        <TableCell>{created}</TableCell>
        <TableCell>{reserved}</TableCell>
        <TableCell>{issued}</TableCell>
        {(workMode === 'cpa' || statsType === 'summaryStatsForAdmin') && <TableCell>{formattedPurchasesConversion}%</TableCell>}
        <TableCell><Currency>{formatCurrency(revenue)}</Currency></TableCell>
        {(statsType === 'summaryStatsForAdmin' || showRefferralsRevenue) && (
          <TableCell><Currency>{formatCurrency(referralsRevenue)}</Currency></TableCell>
        )}
        {statsType === 'summaryStatsForAdmin' && (
          <TableCell><Currency>{formatCurrency(profit)}</Currency></TableCell>
        )}
        {statsType === 'summaryStatsForAdmin' && (
          <TableCell><Currency>{formatCurrency(orderTotal)}</Currency></TableCell>
        )}
      </UITableRow>
    )
  }
}

export default TableRow
