import React, { Component } from 'react'
import { withMedia } from '@kupibilet/ui/utils/media-queries'
import AppContext from 'partner/contexts/AppContext'
import InnerHeader from './components/InnerHeader'

class Header extends Component {
  static contextType = AppContext

  render() {
    return <InnerHeader {...this.context} {...this.props} />
  }
}

export default withMedia(Header)
