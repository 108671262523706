import React from 'react'
import { Provider } from 'react-redux'
import { PageTitle, H1 } from 'shared/components'

import { Tabs, TabPane, TabBar, Tab as StyledTab } from '@kupibilet/ui/blocks/Tabs'

import LinkConstructor from 'shared/pages/Constructors/constructors/Link'
import FormConstructor from 'shared/pages/Constructors/constructors/SearchForm'
import LPCConstructor from 'shared/pages/Constructors/constructors/LPC'
import { Container } from './styled'
import { store } from './store'

/* eslint-disable react/prop-types */
const renderTab = (props) => (
  <StyledTab {...props} />
)

const renderTabBar = (props) => (
  <div style={{ marginBottom: '24px' }}>
    <TabBar {...props} renderTab={renderTab} />
  </div>
)

class Constructors extends React.Component {
  state = {
    activeKey: '1',
  }

  onTabChange = (activeKey) => this.setState({ activeKey })

  render() {
    const { activeKey } = this.state
    const { agentCode } = this.props

    return (
      <Provider store={store}>
        <Container>
          <PageTitle>Промо-материалы</PageTitle>
          <H1>Промо-материалы</H1>
          <Tabs
            activeKey={activeKey}
            renderTabBar={renderTabBar}
            onTabChange={this.onTabChange}
          >
            <TabPane tab="Конструктор ссылок" key="1">
              <LinkConstructor agentCode={agentCode} />
            </TabPane>
            <TabPane tab="Виджет формы" key="2">
              <FormConstructor agentCode={agentCode} />
            </TabPane>
            <TabPane tab="Виджет календаря цен" key="3">
              <LPCConstructor agentCode={agentCode} />
            </TabPane>
          </Tabs>
        </Container>
      </Provider>
    )
  }
}

export default Constructors
